var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _vm._v(" " + _vm._s(_vm.$t("table.isPremium")) + "： "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "130px" },
              attrs: { placeholder: _vm.$t("table.isPremium"), clearable: "" },
              model: {
                value: _vm.listQuery.isPremium,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "isPremium", $$v)
                },
                expression: "listQuery.isPremium",
              },
            },
            _vm._l(_vm.boolTypeOptions, function (item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.displayName, value: item.key },
              })
            }),
            1
          ),
          _vm._v(" " + _vm._s(_vm.$t("table.isBetaUser")) + "： "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "130px" },
              attrs: { placeholder: _vm.$t("table.isBetaUser"), clearable: "" },
              model: {
                value: _vm.listQuery.isBetaUser,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "isBetaUser", $$v)
                },
                expression: "listQuery.isBetaUser",
              },
            },
            _vm._l(_vm.boolTypeOptions, function (item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.displayName, value: item.key },
              })
            }),
            1
          ),
          _vm._v(" " + _vm._s(_vm.$t("table.isAssistant")) + "： "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "130px" },
              attrs: {
                placeholder: _vm.$t("table.isAssistant"),
                clearable: "",
              },
              model: {
                value: _vm.listQuery.isAssistant,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "isAssistant", $$v)
                },
                expression: "listQuery.isAssistant",
              },
            },
            _vm._l(_vm.boolTypeOptions, function (item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.displayName, value: item.key },
              })
            }),
            1
          ),
          _vm._v(" " + _vm._s(_vm.$t("table.loginType")) + "： "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "130px" },
              attrs: { placeholder: _vm.$t("table.loginType"), clearable: "" },
              model: {
                value: _vm.listQuery.loginType,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "loginType", $$v)
                },
                expression: "listQuery.loginType",
              },
            },
            _vm._l(_vm.loginTypeOptions, function (item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.displayName, value: item.key },
              })
            }),
            1
          ),
          _c("br"),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t("table.search") },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.handleFilter.apply(null, arguments)
              },
            },
            model: {
              value: _vm.listQuery.text,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "text", $$v)
              },
              expression: "listQuery.text",
            },
          }),
          _c("br"),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter },
            },
            [_vm._v(" " + _vm._s(_vm.$t("table.search")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading...",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c(
            "el-table-column",
            {
              attrs: { align: "center", label: "ID", width: "95" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ $index }) {
                    return [_vm._v(_vm._s($index))]
                  },
                },
              ]),
            },
            [_vm._v(" an ")]
          ),
          _c("el-table-column", {
            attrs: { label: "First Name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_vm._v(" " + _vm._s(row.firstName) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Last Name", align: "center", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_vm._v(" " + _vm._s(row.lastName) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Email", align: "center", width: "115" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_vm._v(" " + _vm._s(row.email) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Login Type", align: "center", width: "115" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_vm._v(" " + _vm._s(row.loginType) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Date of Entry", align: "center", width: "220" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("i", { staticClass: "el-icon-time" }),
                    _c("span", [_vm._v(_vm._s(row.dateOfEntry))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Is Beta User?", align: "center", width: "220" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("i", { staticClass: "el-icon-theme" }),
                    _c("span", [_vm._v(_vm._s(row.isBetaUser))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.actions"),
              align: "center",
              width: "400",
              "class-name": "fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(row)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("table.edit")) + " ")]
                    ),
                    row.isBetaUser !== true
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "success" },
                            on: {
                              click: function ($event) {
                                return _vm.handleToggleBeta(
                                  row,
                                  row.userId,
                                  $event
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("table.enableBeta")) + " "
                            ),
                          ]
                        )
                      : row.isBetaUser === true
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.handleToggleBeta(
                                  row,
                                  row.userId,
                                  $event
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("table.disableBeta")) + " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    row.canUseConsult !== true
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "success" },
                            on: {
                              click: function ($event) {
                                return _vm.handleToggleCanUseConsult(
                                  row,
                                  row.userId
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("table.enableConsult")) + " "
                            ),
                          ]
                        )
                      : row.canUseConsult === true
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.handleToggleCanUseConsult(
                                  row,
                                  row.userId
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("table.disableConsult")) + " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticStyle: { width: "400px", "margin-left": "50px" },
              attrs: {
                rules: _vm.rules,
                model: _vm.tempUserData,
                "label-position": "left",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("columnType.firstName"),
                    title: "First Name",
                    "label-width": "200px",
                    prop: "firstName",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Firstname of user" },
                    model: {
                      value: _vm.tempUserData.firstName,
                      callback: function ($$v) {
                        _vm.$set(_vm.tempUserData, "firstName", $$v)
                      },
                      expression: "tempUserData.firstName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("columnType.lastName"),
                    "label-width": "200px",
                    prop: "lastName",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Last name of user" },
                    model: {
                      value: _vm.tempUserData.lastName,
                      callback: function ($$v) {
                        _vm.$set(_vm.tempUserData, "lastName", $$v)
                      },
                      expression: "tempUserData.lastName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("columnType.email"),
                    prop: "email",
                    "label-width": "200px",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Email" },
                    model: {
                      value: _vm.tempUserData.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.tempUserData, "email", $$v)
                      },
                      expression: "tempUserData.email",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("columnType.countryCode"),
                    prop: "countryCode",
                    "label-width": "200px",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Country Code" },
                    model: {
                      value: _vm.tempUserData.countryCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.tempUserData, "countryCode", $$v)
                      },
                      expression: "tempUserData.countryCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("columnType.phoneNumber"),
                    prop: "phoneNumber",
                    "label-width": "200px",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Phone Number" },
                    model: {
                      value: _vm.tempUserData.phoneNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.tempUserData, "phoneNumber", $$v)
                      },
                      expression: "tempUserData.phoneNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("columnType.country"),
                    "label-width": "200px",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Country" },
                    model: {
                      value: _vm.tempUserData.country,
                      callback: function ($$v) {
                        _vm.$set(_vm.tempUserData, "country", $$v)
                      },
                      expression: "tempUserData.country",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("columnType.city"),
                    "label-width": "200px",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "City" },
                    model: {
                      value: _vm.tempUserData.city,
                      callback: function ($$v) {
                        _vm.$set(_vm.tempUserData, "city", $$v)
                      },
                      expression: "tempUserData.city",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("columnType.userType"),
                    "label-width": "200px",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "User Type" },
                    model: {
                      value: _vm.tempUserData.userType,
                      callback: function ($$v) {
                        _vm.$set(_vm.tempUserData, "userType", $$v)
                      },
                      expression: "tempUserData.userType",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("columnType.caneId"),
                    "label-width": "200px",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Cane ID" },
                    model: {
                      value: _vm.tempUserData.caneId,
                      callback: function ($$v) {
                        _vm.$set(_vm.tempUserData, "caneId", $$v)
                      },
                      expression: "tempUserData.caneId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("columnType.assistant"),
                    "label-width": "200px",
                  },
                },
                [
                  _c("el-switch", {
                    attrs: {
                      "inline-prompt": "",
                      "active-text": _vm.$t("Yes"),
                      "inactive-text": _vm.$t("No"),
                    },
                    model: {
                      value: _vm.tempUserData.isAssistant,
                      callback: function ($$v) {
                        _vm.$set(_vm.tempUserData, "isAssistant", $$v)
                      },
                      expression: "tempUserData.isAssistant",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("columnType.minutes"),
                    "label-width": "200px",
                  },
                },
                [
                  _c("el-input-number", {
                    attrs: { placeholder: "Minutes" },
                    model: {
                      value: _vm.tempUserData.consultSeconds,
                      callback: function ($$v) {
                        _vm.$set(_vm.tempUserData, "consultSeconds", $$v)
                      },
                      expression: "tempUserData.consultSeconds",
                    },
                  }),
                ],
                1
              ),
              _vm.tempUserData.isAssistant
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("columnType.assistantRegion"),
                        "label-width": "200px",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { multiple: "" },
                          model: {
                            value: _vm.tempUserData.assistantRegion,
                            callback: function ($$v) {
                              _vm.$set(_vm.tempUserData, "assistantRegion", $$v)
                            },
                            expression: "tempUserData.assistantRegion",
                          },
                        },
                        _vm._l(_vm.applicationRegions, function (option) {
                          return _c("el-option", {
                            key: option,
                            attrs: { label: option, value: option },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("table.cancel")) + " ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus === "create"
                        ? _vm.createData()
                        : _vm.updateData()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("table.confirm")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }