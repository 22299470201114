var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-date-picker", {
            staticClass: "filter-item",
            staticStyle: { width: "200px", "margin-right": "20px" },
            attrs: {
              type: "date",
              placeholder: "Start Time",
              "value-format": "yyyy-MM-dd",
            },
            model: {
              value: _vm.listQuery.startTime,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "startTime", $$v)
              },
              expression: "listQuery.startTime",
            },
          }),
          _c("el-date-picker", {
            staticClass: "filter-item",
            staticStyle: { width: "200px", "margin-right": "20px" },
            attrs: {
              type: "date",
              placeholder: "End Time",
              "value-format": "yyyy-MM-dd",
            },
            model: {
              value: _vm.listQuery.endTime,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "endTime", $$v)
              },
              expression: "listQuery.endTime",
            },
          }),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "130px", "margin-right": "20px" },
              attrs: { placeholder: _vm.$t("table.source"), clearable: "" },
              model: {
                value: _vm.listQuery.source,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "source", $$v)
                },
                expression: "listQuery.source",
              },
            },
            _vm._l(_vm.sourceOptions, function (item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.displayName, value: item.key },
              })
            }),
            1
          ),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px", "margin-right": "20px" },
            attrs: { placeholder: _vm.$t("table.userIdSearch") },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.handleFilter.apply(null, arguments)
              },
            },
            model: {
              value: _vm.listQuery.userId,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "userId", $$v)
              },
              expression: "listQuery.userId",
            },
          }),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter },
            },
            [_vm._v(" " + _vm._s(_vm.$t("table.search")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { "margin-bottom": "20px" },
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading...",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c(
            "el-table-column",
            {
              attrs: { align: "center", label: "ID", width: "95" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ $index }) {
                    return [_vm._v(_vm._s($index))]
                  },
                },
              ]),
            },
            [_vm._v(" an ")]
          ),
          _c("el-table-column", {
            attrs: { label: "Session Id" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_vm._v(" " + _vm._s(row.sessionId) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "User Id", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_c("span", [_vm._v(_vm._s(row.userId))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Source", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_c("span", [_vm._v(_vm._s(row.source))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Start Time", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_c("span", [_vm._v(_vm._s(row.startTime))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "End Time", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_c("span", [_vm._v(_vm._s(row.endTime))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Step Count", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_c("span", [_vm._v(_vm._s(row.stepCount))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Distance", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_c("span", [_vm._v(_vm._s(row.distance))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Swipe Count", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_c("span", [_vm._v(_vm._s(row.swipeCount))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Swipe Max Angle", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_c("span", [_vm._v(_vm._s(row.swipeMaxAngle))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Swipe Min Angle", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_c("span", [_vm._v(_vm._s(row.swipeMinAngle))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Swipe Average Angle", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_c("span", [_vm._v(_vm._s(row.swipeAverageAngle))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Pitching Max Angle", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_c("span", [_vm._v(_vm._s(row.pitchingMaxAngle))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Pitching Min Angle", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_c("span", [_vm._v(_vm._s(row.pitchingMinAngle))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Pitching Average Angle", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("span", [_vm._v(_vm._s(row.pitchingAverageAngle))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Journey Duration", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_c("span", [_vm._v(_vm._s(row.journeyDuration))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Review Filled", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_c("span", [_vm._v(_vm._s(row.reviewFilled))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "LocalTimeAsUTC", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_c("span", [_vm._v(_vm._s(row.localTimeAsUTC))])]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.listQuery.page,
          "page-size": _vm.listQuery.pageSize,
          "page-sizes": [10, 20, 30, 40, 50, 100, 200, 300, 400, 500, 1000],
          small: _vm.small,
          background: true,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.totalCount,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }