
import { Component, Vue } from 'vue-property-decorator'
import { AdminApi } from '@/wwapi/apis/admin-api'
import { defaultCallEventData } from '../../defaultModels/ICallEventData'
import { CallEventModel } from '@/wwapi/models/callEvent-model'
import { GetCallEventsResponse } from '@/wwapi/models/get-callEvents-response'

const countryOptions = [
  { key: 'tr', displayName: 'TR' },
  { key: 'gb', displayName: 'GB' },
  { key: '', displayName: 'leave empty' }
]
  @Component({
    name: 'Call Events'
  })
export default class extends Vue {
    private list: CallEventModel[] = []
    private totalCount = 0;
    private listLoading = true
    private countryOptions = countryOptions

    private listQuery = {
      text: '',
      country: undefined,
      userId: undefined,
      startDate: undefined,
      endDate: undefined,
      page: 1,
      pageSize: 10,
      sort: '+id'
    }

    created() {
      this.fetchData()
    }

    private async fetchData() {
      try {
        this.listQuery.userId = this.listQuery.userId === '' ? undefined : this.listQuery.userId
        this.listLoading = true
        const adminApi = new AdminApi()
        console.dir(this.listQuery)
        const response = await adminApi.callEventListPost(this.listQuery)
        console.log(response)
        const responseModel: GetCallEventsResponse = response.data
        const callEvents: CallEventModel[] = responseModel.data
        if (callEvents.length && callEvents.length > 0) {
          this.list = callEvents
          this.totalCount = response.data.pagination.totalCount
        } else {
          this.list = []
          this.totalCount = 0
        }
        console.log('data returned from getCallEvents' + JSON.stringify(callEvents))

        // this.list = response.data.items;
        // Just to simulate the time of the request
        setTimeout(() => {
          this.listLoading = false
        }, 0.5 * 1000)
      } catch (error) {
        alert(error)
      }
    }

    private handleFilter() {
      this.fetchData()
    }

    private handleCurrentChange(newPage: number) {
      this.listQuery.page = newPage
      this.fetchData()
    }

    private handleSizeChange(newPageSize: number) {
      this.listQuery.pageSize = newPageSize
      this.fetchData()
    }

    generateDynamicURL() {
      const baseURL = `${process.env.VUE_APP_API_URL}/admin/exportUserConsultInformation`
      const excelListQuery = { ...this.listQuery }
      excelListQuery.pageSize = 100000
      const queryParams = []

      if (excelListQuery.text !== undefined) {
        queryParams.push(`text=${excelListQuery.text}`)
      }
      if (excelListQuery.country !== undefined) {
        queryParams.push(`country=${excelListQuery.country}`)
      }
      if (excelListQuery.startDate !== undefined) {
        queryParams.push(`startDate=${excelListQuery.startDate}`)
      }
      if (excelListQuery.endDate !== undefined) {
        queryParams.push(`endDate=${excelListQuery.endDate}`)
      }
      if (excelListQuery.page !== undefined) {
        queryParams.push(`page=${excelListQuery.page}`)
      }
      if (excelListQuery.pageSize !== undefined) {
        queryParams.push(`pageSize=${excelListQuery.pageSize}`)
      }
      if (excelListQuery.userId !== undefined) {
        queryParams.push(`userId=${excelListQuery.userId}`)
      }

      const queryString = queryParams.join('&')
      return `${baseURL}?${queryString}`
    }
}
