
import { Component, Vue } from "vue-property-decorator";
import { UserModule } from "@/store/modules/user";
import AdminDashboard from "./admin/index.vue";
import ProductTeamDashboard from "./product-team/index.vue";

@Component({
  name: "Dashboard",
  components: {
    AdminDashboard,
    ProductTeamDashboard,
  },
})
export default class extends Vue {
  private currentRole = "admin-dashboard";

  get roles() {
    return UserModule.roles;
  }

  created() {
    console.log(process.env.VUE_APP_API_URL);
    if (!this.roles.includes("admin")) {
      if (this.roles.includes("product-team")) {
        this.currentRole = "product-team-dashboard";
      }
    }
  }
}
