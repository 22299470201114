var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "screenfull" } },
    [
      _c("svg-icon", {
        attrs: { name: _vm.isFullscreen ? "exit-fullscreen" : "fullscreen" },
        on: { click: _vm.click },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }