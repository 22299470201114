
import { Component, Vue } from 'vue-property-decorator'
import { AdminApi } from '@/wwapi/apis/admin-api'
import { cloneDeep } from 'lodash'
import { Form } from 'element-ui'
import { GetAssistantConfigsResponse } from '@/wwapi/models/get-assistantConfigs-response'
import { defaultAssistantConfigData } from '@/defaultModels/IAssistantConfigData'

// ];
@Component({
  name: 'AssistantConfigList'
})
export default class extends Vue {
  private list: GetAssistantConfigsResponse[] = []
  private applicationRegions: string[] = [];
  private listLoading = true
  private dialogFormVisible = false
  private dialogStatus = ''
  private deleteConfirmationVisible = false
  private deleteItemId = null
  private tempAssistantConfigData = defaultAssistantConfigData
  private days: any = []
  private textMap = {
    update: 'Edit',
    create: 'Create'
  }

  private tagColors: string[] = [
    'success',
    'warning',
    'primary',
    'danger',
    'info',
    'success',
    'warning'
  ];

  get pickerOptions() {
    return {
      start: '00:00',
      end: '23:59',
      step: '00:15',
      format: 'HH:mm'
    }
  }

  created() {
    this.fetchData()
    this.days = [
      { label: 'Monday', value: 'monday' },
      { label: 'Tuesday', value: 'tuesday' },
      { label: 'Wednesday', value: 'wednesday' },
      { label: 'Thursday', value: 'thursday' },
      { label: 'Friday', value: 'friday' },
      { label: 'Saturday', value: 'saturday' },
      { label: 'Sunday', value: 'sunday' }
    ]
  }

  private getTagType(index: number): string {
    return this.tagColors[index % this.tagColors.length] || ''
  }

  private async fetchData() {
    this.listLoading = true
    const adminApi = new AdminApi()
    const response = await adminApi.getAssistantConfigs()
    if (response.data.data.length) this.list = response.data.data
    else this.list = []
    const applicationRegionResponse = await adminApi.getClientSettings()
    const regions = applicationRegionResponse.data.data.find(x => x.key === 'CONSULT_REGIONS')
    this.applicationRegions = regions!.value.split(';')
    console.log(
      'data returned from getAssistantConfigs' +
      JSON.stringify(response.data.data)
    )
    setTimeout(() => {
      this.listLoading = false
    }, 0.5 * 1000)
  }

  handleWorkingHoursStartChange(value: string) {
    this.tempAssistantConfigData.workingHoursStart = value
  }

  handleWorkingHoursEndChange(value: string) {
    this.tempAssistantConfigData.workingHoursEnd = value
  }

  showDeleteConfirmation(row: any) {
    this.deleteItemId = row.id
    this.deleteConfirmationVisible = true
  }

  handleDeleteConfirmationClose(done: any) {
    if (done) {
      this.deleteItemId = null
      this.deleteConfirmationVisible = false
    }
  }

  async deleteItem() {
    console.log(`Deleting item with ID: ${this.deleteItemId}`)
    const adminApi = new AdminApi()
    const { data } = await adminApi.deleteAssistantConfig({
      assistantConfigId: this.deleteItemId
    })
    if (data.status.code === 'success') {
      const index = this.list.findIndex((v: any) => v.id === this.deleteItemId)
      this.list.splice(index, 1)
      this.$notify({
        title: 'Success',
        message: 'Successfully assistant config deleted',
        type: 'success',
        duration: 2000
      })
    }
    this.deleteItemId = null
    this.deleteConfirmationVisible = false
  }

  private handleUpdate(row: any) {
    this.tempAssistantConfigData = Object.assign({}, row)
    this.dialogStatus = 'update'
    this.dialogFormVisible = true
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate()
    })
  }

  private updateData() {
    const adminApi = new AdminApi()
    ;(this.$refs.dataForm as Form).validate(async(valid) => {
      if (valid) {
        const tempData = Object.assign({}, this.tempAssistantConfigData)
        try {
          const { data } = await adminApi.updateAssistantConfig({
            id: tempData.id,
            workingHoursStart: tempData.workingHoursStart,
            workingHoursEnd: tempData.workingHoursEnd,
            workingDays: tempData.workingDays,
            region: tempData.region,
            isActive: tempData.isActive,
            isRemoved: false
          })
          const index = this.list.findIndex((v: any) => v.id === data.data.id)
          if (data.status.code === 'success') {
            this.list.splice(index, 1, data.data)
            this.dialogFormVisible = false
            this.$notify({
              title: 'Success',
              message: 'Successfully update the assistant config',
              type: 'success',
              duration: 2000
            })
          }
        } catch (error) {
          if (error.response) {
            this.dialogFormVisible = false
            this.$notify({
              title: 'Error',
              message: error.response.data.status.message,
              type: error.response.data.status.code,
              duration: 2000
            })
          }
        }
      }
    })
  }

  private handleCreate() {
    this.tempAssistantConfigData = cloneDeep(defaultAssistantConfigData)
    this.dialogStatus = 'create'
    this.dialogFormVisible = true
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate()
    })
  }

  private createData() {
    const adminApi = new AdminApi()

    ;(this.$refs.dataForm as Form).validate(async(valid) => {
      if (valid) {
        const tempData = Object.assign({}, this.tempAssistantConfigData)
        try {
          const { data } = await adminApi.postAssistantConfig({
            workingHoursStart: tempData.workingHoursStart,
            workingHoursEnd: tempData.workingHoursEnd,
            workingDays: tempData.workingDays,
            region: tempData.region,
            isActive: tempData.isActive,
            isRemoved: false
          })
          if (data.status.code === 'success') {
            if (data && data.data) {
              this.list = [...this.list, data.data]
            }
            this.dialogFormVisible = false
            this.$notify({
              title: 'Success',
              message: 'Successfully added new package',
              type: 'success',
              duration: 2000
            })
          }
        } catch (error) {
          if (error.response) {
            this.dialogFormVisible = false
            this.$notify({
              title: 'Error',
              message: error.response.data.status.message,
              type: error.response.data.status.code,
              duration: 2000
            })
          }
        }
      }
    })
  }

  private async toggleAssistantConfigActive(row: any, isActive: boolean) {
    const adminApi = new AdminApi()
    row.isActive = isActive
    const { data } = await adminApi.updateAssistantConfig({
      id: row.id,
      region: row.region,
      workingHoursStart: row.workingHoursStart,
      workingHoursEnd: row.workingHoursEnd,
      workingDays: row.workingDays,
      isActive: row.isActive,
      isRemoved: row.isRemoved
    })
    if (data.status.code === 'success') {
      if (!isActive) {
        const index = this.list.findIndex((v: any) => v.id === data.data.id)
        this.list.splice(index, 1, row)
      }
      this.$notify({
        title: 'Success',
        message: 'Successfully assistantConfig status changed',
        type: 'success',
        duration: 2000
      })
    }
  }
}
