var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "user-activity" }, [
    _c("div", { staticClass: "post" }, [
      _c("div", { staticClass: "user-block" }, [
        _c("img", {
          staticClass: "img-circle",
          attrs: {
            alt: "avatar",
            src:
              "https://wpimg.wallstcn.com/57ed425a-c71e-4201-9428-68760c0537c4.jpg" +
              _vm.avatarPrefix,
          },
        }),
        _c("span", { staticClass: "username text-muted" }, [
          _vm._v("Iron Man"),
        ]),
        _c("span", { staticClass: "description" }, [
          _vm._v("Shared publicly - 7:30 PM today"),
        ]),
      ]),
      _c("p", [
        _vm._v(
          " Lorem ipsum represents a long-held tradition for designers, typographers and the like. Some people hate it and argue for its demise, but others ignore the hate as they create awesome tools to help create filler text for everyone from bacon lovers to Charlie Sheen fans. "
        ),
      ]),
      _c("ul", { staticClass: "list-inline" }, [
        _vm._m(0),
        _c("li", [
          _c(
            "span",
            { staticClass: "link-black text-sm" },
            [_c("svg-icon", { attrs: { name: "like" } }), _vm._v(" Like ")],
            1
          ),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "post" }, [
      _c("div", { staticClass: "user-block" }, [
        _c("img", {
          staticClass: "img-circle",
          attrs: {
            alt: "avatar",
            src:
              "https://wpimg.wallstcn.com/9e2a5d0a-bd5b-457f-ac8e-86554616c87b.jpg" +
              _vm.avatarPrefix,
          },
        }),
        _c("span", { staticClass: "username text-muted" }, [
          _vm._v("Captain American"),
        ]),
        _c("span", { staticClass: "description" }, [
          _vm._v("Sent you a message - yesterday"),
        ]),
      ]),
      _c("p", [
        _vm._v(
          " Lorem ipsum represents a long-held tradition for designers, typographers and the like. Some people hate it and argue for its demise, but others ignore the hate as they create awesome tools to help create filler text for everyone from bacon lovers to Charlie Sheen fans. "
        ),
      ]),
      _c("ul", { staticClass: "list-inline" }, [
        _vm._m(1),
        _c("li", [
          _c(
            "span",
            { staticClass: "link-black text-sm" },
            [_c("svg-icon", { attrs: { name: "like" } }), _vm._v(" Like ")],
            1
          ),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "post" }, [
      _c("div", { staticClass: "user-block" }, [
        _c("img", {
          staticClass: "img-circle",
          attrs: {
            alt: "avatar",
            src:
              "https://wpimg.wallstcn.com/fb57f689-e1ab-443c-af12-8d4066e202e2.jpg" +
              _vm.avatarPrefix,
          },
        }),
        _c("span", { staticClass: "username" }, [_vm._v("Spider Man")]),
        _c("span", { staticClass: "description" }, [
          _vm._v("Posted 4 photos - 2 days ago"),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "user-images" },
        [
          _c(
            "el-carousel",
            { attrs: { interval: 6000, type: "card", height: "220px" } },
            _vm._l(_vm.carouselImages, function (item) {
              return _c("el-carousel-item", { key: item }, [
                _c("img", {
                  staticClass: "image",
                  attrs: { src: item + _vm.carouselPrefix, alt: "image" },
                }),
              ])
            }),
            1
          ),
        ],
        1
      ),
      _c("ul", { staticClass: "list-inline" }, [
        _vm._m(2),
        _c("li", [
          _c(
            "span",
            { staticClass: "link-black text-sm" },
            [_c("svg-icon", { attrs: { name: "like" } }), _vm._v(" Like")],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("li", [
      _c("span", { staticClass: "link-black text-sm" }, [
        _c("i", { staticClass: "el-icon-share" }),
        _vm._v(" Share "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("li", [
      _c("span", { staticClass: "link-black text-sm" }, [
        _c("i", { staticClass: "el-icon-share" }),
        _vm._v(" Share "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("li", [
      _c("span", { staticClass: "link-black text-sm" }, [
        _c("i", { staticClass: "el-icon-share" }),
        _vm._v(" Share"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }