
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'SourceUrlDropdown'
})
export default class extends Vue {
  @Prop({ required: true }) private value!: string

  get sourceURL() {
    return this.value
  }

  set sourceURL(value) {
    this.$emit('input', value)
  }
}
