
import { Component, Vue } from "vue-property-decorator";
import { AdminApi } from "@/wwapi/apis/admin-api";
import { SessionsModel } from "@/wwapi/models/sessions-model";
import { GetSessionsResponse } from "@/wwapi/models/get-sessions-response";

const sourceOptions = [
  { key: "android", displayName: "Android" },
  { key: "ios", displayName: "Ios" },
  { key: "", displayName: "leave empty" },
];
@Component({
  name: "Sessions",
})
export default class extends Vue {
  private list: SessionsModel[] = [];
  private totalCount = 0;
  private listLoading = true;
  private sourceOptions = sourceOptions;

  private listQuery = {
    source: undefined,
    userId: undefined,
    startTime: undefined,
    endTime: undefined,
    page: 1,
    size: 10,
    sort: "+id",
  };

  created() {
    this.fetchData();
  }

  private async fetchData() {
    try {
      this.listLoading = true;
      const adminApi = new AdminApi();
      console.dir(this.listQuery);
      const response = await adminApi.sessionsListPost(this.listQuery);
      console.log(response);
      const responseModel: GetSessionsResponse = response.data;
      const sessions: SessionsModel[] = responseModel.data;
      if (sessions.length && sessions.length > 0) {
        this.list = sessions;
        this.totalCount = response.data.pagination.totalCount;
      } else {
        this.list = [];
        this.totalCount = 0;
      }
      console.log("data returned from getSessions" + JSON.stringify(sessions));

      // this.list = response.data.items;
      // Just to simulate the time of the request
      setTimeout(() => {
        this.listLoading = false;
      }, 0.5 * 1000);
    } catch (error) {
      alert(error);
    }
  }

  private handleFilter() {
    this.fetchData();
  }

  private handleCurrentChange(newPage: number) {
    this.listQuery.page = newPage;
    this.fetchData();
  }

  private handleSizeChange(newPageSize: number) {
    this.listQuery.size = newPageSize;
    this.fetchData();
  }
}
