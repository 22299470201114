var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", {}, [
    _c("div", [
      _c(
        "div",
        { staticClass: "app-container" },
        [
          _c(
            "div",
            { staticClass: "button-container" },
            [
              _c(
                "el-button",
                {
                  staticClass: "btn-add-subject",
                  attrs: { type: "success" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCreate()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-plus" }),
                  _vm._v(" " + _vm._s(_vm.$t("subject.addNewSubject")) + " "),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              attrs: {
                data: _vm.list,
                "element-loading-text": "Loading...",
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c(
                "el-table-column",
                {
                  attrs: { align: "center", label: "ID", width: "95" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ $index }) {
                        return [_vm._v(_vm._s($index))]
                      },
                    },
                  ]),
                },
                [_vm._v(" an ")]
              ),
              _c("el-table-column", {
                attrs: { label: _vm.$t("subject.name"), align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [_vm._v(" " + _vm._s(row.name) + " ")]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("subject.state"),
                  align: "center",
                  width: "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.isActive
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "success" },
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleSubjectActive(row, false)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("subject.active")) + " "
                                ),
                              ]
                            )
                          : _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleSubjectActive(row, true)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("subject.passive")) + " "
                                ),
                              ]
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("company.actions"),
                  align: "center",
                  "class-name": "fixed-width",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.handleUpdate(row)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("company.edit")) + " ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.textMap[_vm.dialogStatus],
                visible: _vm.dialogFormVisible,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  staticStyle: { width: "400px", "margin-left": "50px" },
                  attrs: {
                    rules: _vm.rules,
                    model: _vm.tempSubjectData,
                    "label-position": "left",
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("subject.name"),
                        "label-width": "200px",
                        prop: "name",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Subject Name" },
                        model: {
                          value: _vm.tempSubjectData.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.tempSubjectData, "name", $$v)
                          },
                          expression: "tempSubjectData.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("subject.state"),
                        "label-width": "200px",
                      },
                    },
                    [
                      _c("el-switch", {
                        attrs: {
                          "inline-prompt": "",
                          "active-text": _vm.$t("subject.active"),
                          "inactive-text": _vm.$t("subject.passive"),
                        },
                        model: {
                          value: _vm.tempSubjectData.isActive,
                          callback: function ($$v) {
                            _vm.$set(_vm.tempSubjectData, "isActive", $$v)
                          },
                          expression: "tempSubjectData.isActive",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible = false
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("table.cancel")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.dialogStatus === "create"
                            ? _vm.createData()
                            : _vm.updateData()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("table.confirm")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }