export default {
  route: {
    dashboard: "Gösterge Paneli",
    documentation: "Dokümantasyon",
    guide: "Rehber",
    permission: "İzinler",
    pagePermission: "Sayfa İzni",
    rolePermission: "Rol İzni",
    directivePermission: "Direktif İzni",
    icons: "İkonlar",
    components: "Bileşenler",
    tinymce: "Tinymce",
    markdown: "Markdown",
    jsonEditor: "JSON Editörü",
    splitPane: "Bölünmüş Panel",
    avatarUpload: "Avatar Yükle",
    dropzone: "Dropzone",
    sticky: "Yapışkan",
    countTo: "Sayaç",
    componentMixin: "Bileşen Karışımı",
    backToTop: "Yukarı Çık",
    draggableDialog: "Sürüklenebilir Diyalog",
    draggableKanban: "Sürüklenebilir Kanban",
    draggableList: "Sürüklenebilir Liste",
    draggableSelect: "Sürüklenebilir Seçim",
    charts: "Grafikler",
    barChart: "Çubuk Grafik",
    lineChart: "Çizgi Grafik",
    mixedChart: "Karma Grafik",
    example: "Örnek",
    nested: "İç İçe Rotalar",
    menu1: "Menü 1",
    "menu1-1": "Menü 1-1",
    "menu1-2": "Menü 1-2",
    "menu1-2-1": "Menü 1-2-1",
    "menu1-2-2": "Menü 1-2-2",
    "menu1-3": "Menü 1-3",
    menu2: "Menü 2",
    table: "Tablo",
    dynamicTable: "Dinamik Tablo",
    draggableTable: "Sürüklenebilir Tablo",
    inlineEditTable: "Satır İçi Düzenleme Tablosu",
    complexTable: "Karmaşık Tablo",
    tab: "Sekme",
    form: "Form",
    createArticle: "Makale Oluştur",
    editArticle: "Makale Düzenle",
    articleList: "Makale Listesi",
    errorPages: "Hata Sayfaları",
    page401: "401",
    page404: "404",
    errorLog: "Hata Kaydı",
    excel: "Excel",
    exportExcel: "Excel Dışa Aktar",
    selectExcel: "Seçileni Dışa Aktar",
    mergeHeader: "Başlığı Birleştir",
    uploadExcel: "Excel Yükle",
    zip: "Zip",
    exportZip: "Zip Dışa Aktar",
    theme: "Tema",
    clipboard: "Pano",
    i18n: "Uluslararasılaştırma",
    externalLink: "Harici Bağlantı",
    wewalkPage: "WeWALK",
    profile: "Profil",
    mobility: "Mobilite",
    addTrainerTrainee: "Eğitmen/Eğitim Alanı Ekle",
    listOrEditTrainerTrainee: "Eğitmen/Eğitim Alanlarını Listele/Düzenle",
    danis: "Danış",
    danisconfig: "Danış Ayarları",
    freeZone: "Serbest Bölge",
    company: "Şirketler",
    packageManagment: "Paket Yönetimi",
    assistantConfig: "Asistan Ayarları",
    userActivations: "Kullanıcı Aktivasyonları",
    callEvents: "Çağrı Etkinlikleri",
    sessions: "Oturumlar",
    subject: "Konu",
    brand: "Marka",
    brandSubject: "Marka Konusu",
    mobile:"Uygulama Yerelleştirme",
    applocalizations: "Uygulama Dilleri",
    applocalizations_detail: "Yerelleştirme Detayı",
    appcoupons: "Kupon Kodları",
    users: "Kullanıcılar",
    listUser: "Listele",
    activations: "Baston Aktivasyonları",
    otafiles: "Ota Dosyaları",
    "redirect-weassist": "WeAssist Panel Giriş",
    pdf: "PDF"
  },
  navbar: {
    logOut: "Çıkış Yap",
    dashboard: "Gösterge Paneli",
    github: "Github",
    theme: "Tema",
    size: "Global Boyut",
    profile: "Profil",
  },
  login: {
    title: "WeWALK Yönetici",
    logIn: "Giriş Yap",
    sendCode: "Kodu Gönder",
    enterCode: "Doğrulama Kodu",
    username: "Kullanıcı Adı",
    password: "Şifre",
    any: "herhangi biri",
    thirdparty: "Veya ile bağlan",
    thirdpartyTips: "Yerelde simüle edilemez, lütfen kendi iş simulasyonunuzu birleştirin!",
    timeUpResendCode: "Süre Doldu, Kodu Yeniden Gönder",
    countryCode: "Ülke Kodu",
    phoneNumber: "Telefon Numarası",
    loginError: "Giriş işleminde bir sorun oluştu. Lütfen tekrar deneyin veya sistem yöneticisi ile iletişime geçin.",
    loginErrorTitle: "Hata!",
    loginWarningTitle: "Uyarı!",
    invalidEmail: "Lütfen geçerli bir e-posta adresi giriniz.",
    invalidPhone: "Lütfen geçerli bir telefon numarası giriniz.",
    notAdmin: "Yönetici değilsiniz. Lütfen WeWALK Sistem & Geliştirici Ekibi ile iletişime geçiniz.",
    invalidPhoneLength: "Lütfen geçerli bir telefon numarası giriniz. Numara 10 karakter uzunluğunda olmalıdır.",
    invalidCountry: "SMS gönderimi sadece Türkiye (+90) için mümkündür. Lütfen e-posta ile giriş yapmayı deneyin.",
    timeUpSecondsLeft: " saniye kaldı.",
    resendCode: "Kodu Yeniden Gönder",
    loginWithPhone: "Telefon İle Giriş Yap",
    loginWithEmail: "E-Posta İle Giriş Yap"
  },
  documentation: {
    documentation: "Dokümantasyon",
    github: "Github Deposu",
  },
  permission: {
    createRole: "Yeni Rol",
    editPermission: "Düzenle",
    roles: "Rolleriniz",
    switchRoles: "Rolleri Değiştir",
    tips: "Bazı durumlarda, v-permission kullanmak işe yaramayabilir. Örneğin: Element-UI el-tab veya el-table-column ve dinamik olarak dom render eden diğer senaryolar. Bu durumda yalnızca v-if ile bunu yapabilirsiniz.",
    delete: "Sil",
    confirm: "Onayla",
    cancel: "İptal",
  },
  guide: {
    description: "Rehber sayfası, projeye ilk kez giren bazı kişiler için yararlıdır. Projede bulunan özelliklerin kısaca tanıtılabilir.",
    button: "Rehberi Göster",
  },
  components: {
    documentation: "Dokümantasyon",
    tinymceTips: "Zengin metin yönetimi, yönetim arka ucu için temel bir özelliktir, ancak aynı zamanda birçok tuzak içerir.",
    stickyTips: "Sayfa belirlenen pozisyona kaydırıldığında üstte yapışkan olur.",
    backToTopTips1: "Sayfa belirlenen pozisyona kaydırıldığında, sağ alt köşede 'Yukarı Çık' düğmesi görünür",
    backToTopTips2: "Düğmenin stilini özelleştirebilirsiniz, göster/gizle, görünme yüksekliği, geri dönüş yüksekliği.",
    imageUploadTips: "Vue@1 sürümünü kullandığım için, şu anda mockjs ile uyumlu değil, kendim modifiye ettim.",
  },
  table: {
    dynamicTips1: "Sabit başlık, sıralama başlık sırasına göre",
    dynamicTips2: "Sabit olmayan başlık, tıklama sırasına göre sıralama",
    dragTips1: "Varsayılan sıra",
    dragTips2: "Sürükledikten sonraki sıra",
    title: "Başlık",
    importance: "Önem",
    type: "Tür",
    remark: "Açıklama",
    search: "Ara",
    add: "Ekle",
    export: "Dışa Aktar",
    reviewer: "İnceleyen",
    id: "ID",
    date: "Tarih",
    author: "Yazar",
    readings: "Okunma",
    status: "Durum",
    actions: "İşlemler",
    edit: "Düzenle",
    publish: "Yayınla",
    draft: "Taslak",
    delete: "Sil",
    cancel: "İptal",
    confirm: "Onayla",
  },
  example: {
    warning: "Yaratma ve düzenleme sayfaları keep-alive tarafından önbelleğe alınamaz, çünkü keep-alive'ın 'include' özelliği şu anda rotalara göre önbelleğe alma desteklemiyor.",
  },
  errorLog: {
    tips: "Lütfen sağ üst köşedeki hata ikonuna tıklayın",
    description: "Şimdi yönetim sistemi temel olarak spa formunda, bu kullanıcı deneyimini artırır, ama aynı zamanda sayfa problemlerinin olasılığını da artırır.",
    documentation: "Dokümantasyon tanıtımı",
  },
  excel: {
    export: "Dışa Aktar",
    selectedExport: "Seçilenleri Dışa Aktar",
    placeholder: "Lütfen dosya adını girin (varsayılan excel-listesi)",
  },
  zip: {
    export: "Dışa Aktar",
    placeholder: "Lütfen dosya adını girin (varsayılan dosya)",
  },
  pdf: {
    tips: "PDF indirme özelliğini gerçekleştirmek için burada window.print() kullanıyoruz.",
  },
  theme: {
    change: "Temayı Değiştir",
    documentation: "Tema Dokümantasyonu",
    tips: "İpuçları: Navbar üzerindeki tema seçimi ile iki farklı derleme yöntemidir, her biri farklı uygulama senaryoları için geçerlidir.",
  },
  tagsView: {
    refresh: "Yenile",
    close: "Kapat",
    closeOthers: "Diğerlerini Kapat",
    closeAll: "Tümünü Kapat",
  },
  settings: {
    title: "Sayfa Stili Ayarı",
    theme: "Tema Rengi",
    showTagsView: "Etiket Görünümünü Aç",
    showSidebarLogo: "Kenar Çubuğu Logosunu Göster",
    fixedHeader: "Sabit Başlık",
    sidebarTextTheme: "Kenar Çubuğu Metin Teması",
  },
  columnType: {
    firstName: "Adı",
    lastName: "Soyadı",
    email: "E-posta",
    countryCode: "Ülke Telefon Kodu",
    country: "Ülke Adı",
    city: "Şehir Adı",
    userType: "Kullanıcı Tipi",
    caneId: "Baston ID",
    minutes: "Dakika",
    assistant: "Asistan",
    phoneNumber: "Telefon Numarası",
    assistantRegion: "Asistan Bölgesi",
  },  
  package: {
    yearly: "Yıllık",
    monthly: "Aylık",
    "monthly-consult": "Danış - Aylık",
    minutes: "dakika",
    general: "Genel",
    android: "Android",
    ios: "iOS",
    code: "Paket Kodu",
    name: "Paket Adı",
    platform: "Platform",
    type: "Tip",
    balance: "Bakiye",
    order: "Sıra",
    state: "Durum",
    active: "Aktif",
    passive: "Pasif",
    edit: "Düzenle",
    description: "Açıklama",
    buttonText: "Buton Metni",
    addNewPackage: "Yeni Paket Ekle",
    isFirstSubscription: "İlk Abonelik için Hediye",
    title: "Başlık",
  },
  freeZone: {
    addNewSponsor: "Yeni Sponsor Ekle",
    name: "Adı",
    company: "Şirket",
    latitude: "Enlem",
    longitude: "Boylam",
    range: "Menzi",
    isCity: "Şehir Mi?",
    state: "Durum",
    active: "Aktif",
    yes: "Evet",
    no: "Hayır",
    passive: "Pasif",
    edit: "Düzenle",
    delete: "Sil",
    actions: "Eylemler",
    deletePopup: "Bu sponsoru silmek istediğinizden emin misiniz?",
    cancel: "İptal",
    ok: "Tamam",
    dataNotAvailable: "Veri Mevcut Değil",
  },
  assistantConfig: {
    addNewAssistantConfig: "Yeni Asistan Ayarı Ekle",
    region: "Bölge",
    workingHoursStart: "Çalışma Saati Başlangıcı",
    workingHoursEnd: "Çalışma Saati Bitişi",
    workingHours: "Çalışma Saatleri",
    workingDays: "Çalışma Günleri",
    state: "Durum",
    active: "Aktif",
    yes: "Evet",
    no: "Hayır",
    passive: "Pasif",
    edit: "Düzenle",
    delete: "Sil",
    actions: "Eylemler",
    deletePopup: "Bu asistan ayarını silmek istediğinizden emin misiniz?",
    cancel: "İptal",
    ok: "Tamam",
    dataNotAvailable: "Veri Mevcut Değil",
  },
  company: {
    addNewCompany: "Yeni Şirket Ekle",
    header: "Başlık",
    name: "Adı",
    description: "Açıklama",
    link: "Bağlantı",
    order: "Sıra",
    state: "Durum",
    active: "Aktif",
    passive: "Pasif",
    edit: "Düzenle",
    delete: "Sil",
    actions: "Eylemler",
    deletePopup: "Bu şirketi silmek istediğinizden emin misiniz?",
    cancel: "İptal",
    ok: "Tamam",
    dataNotAvailable: "Veri Mevcut Değil",
  },
  subject: {
    addNewSubject: "Yeni Konu Ekle",
    name: "Konu Adı",
    state: "Durum",
    active: "Aktif",
    passive: "Pasif",
    edit: "Düzenle",
    delete: "Sil",
    actions: "Eylemler",
    cancel: "İptal",
    ok: "Tamam",
    dataNotAvailable: "Veri Mevcut Değil",
  },
  brand: {
    addNewBrand: "Yeni Marka Ekle",
    name: "Marka Adı",
    state: "Durum",
    active: "Aktif",
    passive: "Pasif",
    edit: "Düzenle",
    delete: "Sil",
    actions: "Eylemler",
    cancel: "İptal",
    ok: "Tamam",
    dataNotAvailable: "Veri Mevcut Değil",
  },
  brandSubject: {
    addNewBrandSubject: "Yeni Marka Konusu Ekle",
    consultSubjectName: "Danışma Konu Adı",
    consultBrandName: "Danışma Marka Adı",
    orderNo: "Sıra No",
    isConsultFree: "Danışma Ücretsiz Mi?",
    free: "Ücretsiz",
    paid: "Ücretli",
    state: "Durum",
    active: "Aktif",
    passive: "Pasif",
    edit: "Düzenle",
    delete: "Sil",
    actions: "Eylemler",
    cancel: "İptal",
    ok: "Tamam",
    dataNotAvailable: "Veri Mevcut Değil",
  },
  localization: {
    addNewLocalization: "Yeni Yerelleştirme Ekle",
    import: "İçe Aktar",
    export: "Dışa Aktar",
    search: "Ara",
    save: "Kaydet",
    updateVersion: "Sürümü Güncelle",
    delete: "Sil",
    confirmDeleteMessage: "Bu satırı silmek istediğinize emin misiniz?",
    warning: "Uyarı",
    yes: "Evet",
    no: "Hayır",
    success: "Başarılı",
    error: "Hata",
    rowDeleted: "Satır başarıyla silindi!",
    deleteFailed: "Satır silinemedi.",
    versionUpdated: "Sürüm başarıyla güncellendi!",
    emailSent: "Yerelleştirme anahtarları başarıyla şu adrese gönderildi",
    rowUpdated: "Satır başarıyla güncellendi!",
    updateFailed: "Satır güncellenemedi.",
    fileImported: "Dosya başarıyla içe aktarıldı!",
    importFailed: "Dosya içe aktarılamadı.",
    loading: "Yükleniyor...",
    iosKey: "IOS Anahtarı",
    androidKey: "Android Anahtarı",
    enterIosKey: "IOS Anahtarını Girin",
    enterAndroidKey: "Android Anahtarını Girin",
    enterLanguage: "Dil Girin",
    enterValue: "Değer Girin",
    enterKey: "Anahtar Girin",
    languageKey: "Dil",
    value: "Değer",
    key: "Anahtar",
    actions:"İşlemler",
  }  
};
