
import { Component, Vue } from "vue-property-decorator";
import Vue2Filters from "vue2-filters";
import { AdminApi } from "@/wwapi/apis/admin-api";
import { GetAdminPackagesResponse } from "@/wwapi/models/get-admin-packages-response";
import { CouponCodesAndProductsResponse } from "@/wwapi/models/coupon-codes-and-products-response";
import { cloneDeep } from "lodash";
import { Form } from "element-ui";
import { defaultPackageData } from "../../defaultModels/IPackageData";

// ];
@Component({
  name: "PackageManagmentList",
})
export default class extends Vue {
  private list: GetAdminPackagesResponse[] = [];
  private listLoading = true;
  private dialogFormVisible = false;
  private dialogStatus = "";
  private typeOptions = ["monthly", "yearly", "monthly-consult"];
  private platformOptions = ["ios", "android", "general"];
  private tempPackageData = defaultPackageData;

  created() {
    this.fetchData();
  }

  private async fetchData() {
    this.listLoading = true;
    const adminApi = new AdminApi();
    const response = await adminApi.getAdminPackages();
    if (response.data.data.length) this.list = response.data.data;
    else this.list = [];
    console.log(
      "data returned from getpackagemanagments" +
        JSON.stringify(response.data.data)
    );
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  private handleUpdate(row: any) {
    this.tempPackageData = Object.assign({}, row);
    this.tempPackageData.packageDurationInSeconds =
      row.packageDurationInSeconds / 60;
    this.dialogStatus = "update";
    this.dialogFormVisible = true;
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate();
    });
  }

  private updateData() {
    const adminApi = new AdminApi();
    (this.$refs.dataForm as Form).validate(async (valid) => {
      if (valid) {
        const tempData = Object.assign({}, this.tempPackageData);
        try {
          const { data } = await adminApi.adminPostPackage({
            id: tempData.id,
            name: tempData.name,
            description: tempData.description,
            buttonText: tempData.buttonText,
            code: tempData.code,
            platform: tempData.platform,
            type: tempData.type,
            order: tempData.order,
            active: tempData.active,
            // updatedDate: new Date(),
            // createdDate: new Date(),
            packageDurationInSeconds: tempData.packageDurationInSeconds * 60,
            isFirstSubscription: tempData.isFirstSubscription,
            title: tempData.title,
          });
          const index = this.list.findIndex((v: any) => v.id === data.data.id);
          if (data.status.code === "success") {
            this.list.splice(index, 1, data.data);
            this.dialogFormVisible = false;
            this.$notify({
              title: "Success",
              message: "Successfully update the package",
              type: "success",
              duration: 2000,
            });
          }
        } catch (error) {
          if (error.response) {
            this.dialogFormVisible = false;
            this.$notify({
              title: "Error",
              message: error.response.data.status.message,
              type: error.response.data.status.code,
              duration: 2000,
            });
          }
        }
      }
    });
  }

  private handleCreate() {
    this.tempPackageData = cloneDeep(defaultPackageData);
    this.dialogStatus = "create";
    this.dialogFormVisible = true;
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate();
    });
  }

  private createData() {
    const adminApi = new AdminApi();

    (this.$refs.dataForm as Form).validate(async (valid) => {
      if (valid) {
        const tempData = Object.assign({}, this.tempPackageData);
        try {
          const { data } = await adminApi.adminPostPackage({
            name: tempData.name,
            description: tempData.description,
            buttonText: tempData.buttonText,
            code: tempData.code,
            platform: tempData.platform,
            type: tempData.type,
            order: tempData.order || 0,
            active: tempData.active,
            // updatedDate: new Date(),
            // createdDate: new Date(),
            packageDurationInSeconds: tempData.packageDurationInSeconds * 60,
            isFirstSubscription: tempData.isFirstSubscription,
            title: tempData.title,
          });
          if (data.status.code === "success") {
            if (data && data.data) {
              this.list = [...this.list, data.data];
            }
            this.dialogFormVisible = false;
            this.$notify({
              title: "Success",
              message: "Successfully added new package",
              type: "success",
              duration: 2000,
            });
          }
        } catch (error) {
          if (error.response) {
            this.dialogFormVisible = false;
            this.$notify({
              title: "Error",
              message: error.response.data.status.message,
              type: error.response.data.status.code,
              duration: 2000,
            });
          }
        }
      }
    });
  }

  private async togglePackageActive(packageId: string) {
    const adminApi = new AdminApi();
    const { data } = await adminApi.togglePackageActive({ id: packageId });
    if (data.status.code === "success") {
      const index = this.list.findIndex((v: any) => v.id === data.data.id);
      this.list.splice(index, 1, data.data);
      this.$notify({
        title: "Success",
        message: "Successfully package status changed",
        type: "success",
        duration: 2000,
      });
    }
  }
}
