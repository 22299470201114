var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse },
    },
    [
      _c(
        "transition",
        { attrs: { name: "sidebarLogoFade" } },
        [
          _vm.collapse
            ? _c(
                "router-link",
                {
                  key: "collapse",
                  staticClass: "sidebar-logo-link",
                  attrs: { to: "/" },
                },
                [
                  _c("img", {
                    staticClass: "sidebar-logo",
                    attrs: { src: "favicon.ico", alt: "WeWALK Logo" },
                  }),
                ]
              )
            : _c(
                "router-link",
                {
                  key: "expand",
                  staticClass: "sidebar-logo-link",
                  attrs: { to: "/" },
                },
                [
                  _c("img", {
                    staticClass: "sidebar-logo",
                    attrs: { src: "favicon.ico" },
                  }),
                  _c("h1", { staticClass: "sidebar-title" }, [
                    _vm._v(" " + _vm._s(_vm.title) + " "),
                  ]),
                ]
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }