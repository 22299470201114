var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", {}, [
    _vm.list.length
      ? _c("div", [
          _c(
            "div",
            { staticClass: "app-container" },
            [
              _c(
                "div",
                { staticClass: "button-container" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn-add-company",
                      attrs: { type: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.handleCreate()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-plus" }),
                      _vm._v(
                        " " + _vm._s(_vm.$t("company.addNewCompany")) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  attrs: {
                    data: _vm.list,
                    "element-loading-text": "Loading...",
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c(
                    "el-table-column",
                    {
                      attrs: { align: "center", label: "ID", width: "95" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ $index }) {
                              return [_vm._v(_vm._s($index))]
                            },
                          },
                        ],
                        null,
                        false,
                        1833292881
                      ),
                    },
                    [_vm._v(" an ")]
                  ),
                  _c("el-table-column", {
                    attrs: { label: _vm.$t("company.order"), align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [_vm._v(" " + _vm._s(row.orderNo) + " ")]
                          },
                        },
                      ],
                      null,
                      false,
                      3436086576
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: _vm.$t("company.name"), align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [_vm._v(" " + _vm._s(row.name) + " ")]
                          },
                        },
                      ],
                      null,
                      false,
                      228539768
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: _vm.$t("company.header"), align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [_vm._v(" " + _vm._s(row.header) + " ")]
                          },
                        },
                      ],
                      null,
                      false,
                      3929656512
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("company.description"),
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm.showFullDescription[scope.$index]
                                ? _c("div", [
                                    _vm._v(
                                      " " + _vm._s(scope.row.description) + " "
                                    ),
                                  ])
                                : _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.shortenDescription(
                                          scope.row.description
                                        )
                                      )
                                    ),
                                  ]),
                              scope.row.description.length > 20
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { size: "mini", type: "info" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.toggleDescription(
                                                scope.$index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.showFullDescription[
                                                  scope.$index
                                                ]
                                                  ? "Show Less"
                                                  : "Show More"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3262658578
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: _vm.$t("company.link"), align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "a",
                                {
                                  staticClass: "link",
                                  attrs: { href: row.url },
                                },
                                [_vm._v(_vm._s(row.url))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4197039576
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("company.state"),
                      align: "center",
                      width: "180",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.isActive
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "success" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleCompanyActive(
                                            row,
                                            false
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("company.active")) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "danger" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleCompanyActive(
                                            row,
                                            true
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("company.passive")) +
                                          " "
                                      ),
                                    ]
                                  ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      221359484
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("company.actions"),
                      align: "center",
                      "class-name": "fixed-width",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleUpdate(row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("company.edit")) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "danger" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showDeleteConfirmation(row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("company.delete")) + " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3496915534
                    ),
                  }),
                ],
                1
              ),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: _vm.textMap[_vm.dialogStatus],
                    visible: _vm.dialogFormVisible,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogFormVisible = $event
                    },
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "dataForm",
                      staticStyle: { width: "400px", "margin-left": "50px" },
                      attrs: {
                        rules: _vm.rules,
                        model: _vm.tempCompanyData,
                        "label-position": "left",
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("company.name"),
                            "label-width": "200px",
                            prop: "name",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "Company Name" },
                            model: {
                              value: _vm.tempCompanyData.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.tempCompanyData, "name", $$v)
                              },
                              expression: "tempCompanyData.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("company.header"),
                            "label-width": "200px",
                            prop: "header",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 5,
                              placeholder: "Campaign Header",
                            },
                            model: {
                              value: _vm.tempCompanyData.header,
                              callback: function ($$v) {
                                _vm.$set(_vm.tempCompanyData, "header", $$v)
                              },
                              expression: "tempCompanyData.header",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("company.description"),
                            "label-width": "200px",
                            prop: "description",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 5,
                              placeholder: "Campaign Description",
                            },
                            model: {
                              value: _vm.tempCompanyData.description,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.tempCompanyData,
                                  "description",
                                  $$v
                                )
                              },
                              expression: "tempCompanyData.description",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("company.link"),
                            "label-width": "200px",
                            prop: "url",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "Campaign Link" },
                            model: {
                              value: _vm.tempCompanyData.url,
                              callback: function ($$v) {
                                _vm.$set(_vm.tempCompanyData, "url", $$v)
                              },
                              expression: "tempCompanyData.url",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("company.order"),
                            "label-width": "200px",
                            prop: "orderNo",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "number", placeholder: "Order" },
                            model: {
                              value: _vm.tempCompanyData.orderNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.tempCompanyData, "orderNo", $$v)
                              },
                              expression: "tempCompanyData.orderNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("company.state"),
                            "label-width": "200px",
                          },
                        },
                        [
                          _c("el-switch", {
                            attrs: {
                              "inline-prompt": "",
                              "active-text": _vm.$t("company.active"),
                              "inactive-text": _vm.$t("company.passive"),
                            },
                            model: {
                              value: _vm.tempCompanyData.isActive,
                              callback: function ($$v) {
                                _vm.$set(_vm.tempCompanyData, "isActive", $$v)
                              },
                              expression: "tempCompanyData.isActive",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.dialogFormVisible = false
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("table.cancel")) + " ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.dialogStatus === "create"
                                ? _vm.createData()
                                : _vm.updateData()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("table.confirm")) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: "Delete Item",
                    visible: _vm.deleteConfirmationVisible,
                    width: "30%",
                    "before-close": _vm.handleDeleteConfirmationClose,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.deleteConfirmationVisible = $event
                    },
                  },
                },
                [
                  _c("span", [_vm._v(_vm._s(_vm.$t("company.deletePopup")))]),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.deleteConfirmationVisible = false
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("company.cancel")))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.deleteItem },
                        },
                        [_vm._v(_vm._s(_vm.$t("company.ok")))]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ])
      : _c("div", [_vm._v(_vm._s(_vm.$t("company.dataNotAvailable")))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }