/* tslint:disable */
/* eslint-disable */
/**
 * Activation API
 * This module provides endpoints for managing cane activations, including version checking, activation creation, updates, and searches.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
import { Configuration } from "../configuration";
import { BASE_PATH, RequestArgs, BaseAPI, RequiredError } from "../base";
import {
    UserCaneActivationModel,
    UserCaneActivationResponseModel
} from "../models/user-cane-activation-models";
import { ErrorModelResponse } from "../models";

/**
 * ActivationApi - axios parameter creator
 * @export
 */
export const ActivationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get user cane activations
         * @summary Fetches all cane activations for a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCaneActivations: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/cane-activation/get-user-cane-activation`;
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            const query = new URLSearchParams(localVarUrlObj.search);
            localVarUrlObj.search = query.toString();
            let headersFromBaseOptions = baseOptions?.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Check activation version
         * @summary Compares the current activation version and creates a new version if there are changes
         * @param {UserCaneActivationModel} body Activation model containing version details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkActivationVersion: async (body: UserCaneActivationModel, options: any = {}): Promise<RequestArgs> => {
            if (!body) {
                throw new RequiredError("body", "Required parameter body was null or undefined when calling checkActivationVersion.");
            }
            const localVarPath = `/cane-activation/version-check`;
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            const query = new URLSearchParams(localVarUrlObj.search);
            localVarUrlObj.search = query.toString();
            let headersFromBaseOptions = baseOptions?.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = JSON.stringify(body);

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },

        /**
        * Search user cane activations
        * @summary Searches cane activations based on a search string
        * @param {any} body Request body containing search string
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        searchUserCaneActivations: async (body: { searchString: string }, options: any = {}): Promise<RequestArgs> => {
            if (!body) {
                throw new RequiredError("body", "Required parameter body was null or undefined when calling searchUserCaneActivations.");
            }
            const localVarPath = `/cane-activation/search-user-cane-activation`;
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            const query = new URLSearchParams(localVarUrlObj.search);
            localVarUrlObj.search = query.toString();
            let headersFromBaseOptions = baseOptions?.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = JSON.stringify(body);

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
        * Get activation versions
        * @summary Fetches all versions for a specific activation ID
        * @param {number} activationId The ID of the activation
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        getActivationVersions: async (activationId: number, options: any = {}): Promise<RequestArgs> => {
            if (!activationId) {
                throw new RequiredError("activationId", "Required parameter activationId was null or undefined when calling getActivationVersions.");
            }
            const localVarPath = `/cane-activation/get-cane-activation-version`;
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;

            localVarHeaderParameter["Content-Type"] = "application/json";

            const query = new URLSearchParams(localVarUrlObj.search);
            query.append("activationId", activationId.toString());
            localVarUrlObj.search = query.toString();
            let headersFromBaseOptions = baseOptions?.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },

    };
};

/**
 * ActivationApi - functional programming interface
 * @export
 */
export const ActivationApiFp = function (configuration?: Configuration) {
    return {
        /**
         * Get user cane activations
         * @summary Fetches all cane activations for a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserCaneActivations(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCaneActivationResponseModel>> {
            const localVarAxiosArgs = await ActivationApiAxiosParamCreator(configuration).getUserCaneActivations(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Check activation version
         * @summary Compares the current activation version and creates a new version if there are changes
         * @param {UserCaneActivationModel} body Activation model containing version details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkActivationVersion(body: UserCaneActivationModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ErrorModelResponse>> {
            const localVarAxiosArgs = await ActivationApiAxiosParamCreator(configuration).checkActivationVersion(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
        * Search user cane activations
        * @summary Searches cane activations based on a search string
        * @param {any} body Request body containing search string
        * @param {*} [options] Override http request option.
        * @throws {RequiredError}
        */
        async searchUserCaneActivations(body: { searchString: string }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCaneActivationResponseModel>> {
            const localVarAxiosArgs = await ActivationApiAxiosParamCreator(configuration).searchUserCaneActivations(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
         /**
         * Get activation versions
         * @summary Fetches all versions for a specific activation ID
         * @param {number} activationId The ID of the activation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
         async getActivationVersions(activationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCaneActivationResponseModel>> {
            const localVarAxiosArgs = await ActivationApiAxiosParamCreator(configuration).getActivationVersions(activationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
    };
};

/**
 * ActivationApi - object-oriented interface
 * @export
 * @class ActivationApi
 * @extends {BaseAPI}
 */
export class ActivationApi extends BaseAPI {
    /**
     * Get user cane activations
     * @summary Fetches all cane activations for a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivationApi
     */
    public getUserCaneActivations(options?: any) {
        return ActivationApiFp(this.configuration).getUserCaneActivations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Check activation version
     * @summary Compares the current activation version and creates a new version if there are changes
     * @param {UserCaneActivationModel} body Activation model containing version details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivationApi
     */
    public checkActivationVersion(body: UserCaneActivationModel, options?: any) {
        return ActivationApiFp(this.configuration).checkActivationVersion(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
 * Search user cane activations
 * @summary Searches cane activations based on a search string
 * @param {any} body Request body containing search string
 * @param {*} [options] Override http request option.
 * @throws {RequiredError}
 */
    public searchUserCaneActivations(body: { searchString: string }, options?: any) {
        return ActivationApiFp(this.configuration)
            .searchUserCaneActivations(body, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
    * Get activation versions
    * @summary Fetches all versions for a specific activation ID
    * @param {number} activationId The ID of the activation
    * @param {*} [options] Override http request option.
    * @throws {RequiredError}
    */
    public getActivationVersions(activationId: number, options?: any) {
    return ActivationApiFp(this.configuration)
        .getActivationVersions(activationId, options)
        .then((request) => request(this.axios, this.basePath));
}

}
