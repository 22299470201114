var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("el-card", { staticStyle: { "margin-bottom": "20px" } }, [
    _c(
      "div",
      { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
      [_c("span", [_vm._v("About me")])]
    ),
    _c("div", { staticClass: "user-profile" }, [
      _c(
        "div",
        { staticClass: "box-center" },
        [
          _c(
            "pan-thumb",
            {
              attrs: {
                image: _vm.user.avatar,
                height: "100px",
                width: "100px",
                hoverable: false,
              },
            },
            [
              _c("div", [_vm._v("Hello")]),
              _vm._v(" " + _vm._s(_vm.user.roles) + " "),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "box-center" }, [
        _c("div", { staticClass: "user-name text-center" }, [
          _vm._v(" " + _vm._s(_vm.user.email) + " "),
        ]),
        _c("div", { staticClass: "user-role text-center text-muted" }, [
          _vm._v(
            " " + _vm._s(_vm._f("uppercaseFirstChar")(_vm.user.roles)) + " "
          ),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "user-bio" }, [
      _c("div", { staticClass: "user-education user-bio-section" }, [
        _c(
          "div",
          { staticClass: "user-bio-section-header" },
          [
            _c("svg-icon", { attrs: { name: "education" } }),
            _c("span", [_vm._v("Education")]),
          ],
          1
        ),
        _c("div", { staticClass: "user-bio-section-body" }, [
          _c("div", { staticClass: "text-muted" }, [
            _vm._v(
              " JS in Computer Science from the University of Technology "
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "user-skills user-bio-section" }, [
        _c(
          "div",
          { staticClass: "user-bio-section-header" },
          [
            _c("svg-icon", { attrs: { name: "skill" } }),
            _c("span", [_vm._v("Skills")]),
          ],
          1
        ),
        _c("div", { staticClass: "user-bio-section-body" }, [
          _c(
            "div",
            { staticClass: "progress-item" },
            [
              _c("span", [_vm._v("Vue")]),
              _c("el-progress", { attrs: { percentage: 51 } }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "progress-item" },
            [
              _c("span", [_vm._v("Typescript")]),
              _c("el-progress", { attrs: { percentage: 45 } }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "progress-item" },
            [
              _c("span", [_vm._v("Css")]),
              _c("el-progress", { attrs: { percentage: 4 } }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "progress-item" },
            [
              _c("span", [_vm._v("ESLint")]),
              _c("el-progress", {
                attrs: { percentage: 100, status: "success" },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }