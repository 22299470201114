var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "createPost-container" }, [
    _c(
      "div",
      { staticClass: "createPost-main-container" },
      [
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 24 } }, [
              _c(
                "div",
                { staticClass: "postInfo-container" },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _vm._v(" Choose Trainer "),
                          _c(
                            "el-select",
                            {
                              attrs: {
                                "remote-method": _vm.getRemoteUserList,
                                filterable: "",
                                "default-first-option": "",
                                remote: "",
                                placeholder: "Search trainer",
                              },
                              model: {
                                value: _vm.trainerId,
                                callback: function ($$v) {
                                  _vm.trainerId = $$v
                                },
                                expression: "trainerId",
                              },
                            },
                            _vm._l(_vm.userListOptions, function (item, index) {
                              return _c("el-option", {
                                key: item.userId + index,
                                attrs: {
                                  label: item.email,
                                  value: item.userId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _vm._v(" Choose Trainee/s "),
                          _c(
                            "el-select",
                            {
                              attrs: {
                                "remote-method": _vm.getRemoteUserList,
                                filterable: "",
                                "default-first-option": "",
                                multiple: "",
                                remote: "",
                                placeholder: "Search user",
                              },
                              model: {
                                value: _vm.traineeIds,
                                callback: function ($$v) {
                                  _vm.traineeIds = $$v
                                },
                                expression: "traineeIds",
                              },
                            },
                            _vm._l(_vm.userListOptions, function (item, index) {
                              return _c("el-option", {
                                key: item.userId + index,
                                attrs: {
                                  label: item.email,
                                  value: item.userId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "success" },
                          on: {
                            click: function ($event) {
                              return _vm.sendRelationToServer()
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("table.createTrainingRelation")) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }