
import { Component, Vue } from "vue-property-decorator";
import { UserModule } from "@/store/modules/user";

@Component({
  name: "Login",
})
export default class extends Vue {
  private get isLoggedIn(): boolean {
    return UserModule.token !== ""; // Kullanıcı giriş yapmışsa true döner
  }

  private get weAssistLink(): string {
    const token = UserModule.token; // Veya localStorage'dan token alın
    const refreshToken = UserModule.refreshToken; // Veya localStorage'dan refresh token alın
    const baseUrl = process.env.VUE_APP_WEASSIST_URL; // .env dosyasından base URL'i alın
    return `${baseUrl}/auth?token=${token}&refreshToken=${refreshToken}`;
  }
}
