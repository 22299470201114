var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("el-input", {
        staticClass: "filter-item",
        staticStyle: { width: "200px" },
        attrs: { placeholder: _vm.$t("table.search") },
        nativeOn: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.handleFilter.apply(null, arguments)
          },
        },
        model: {
          value: _vm.listQuery.text,
          callback: function ($$v) {
            _vm.$set(_vm.listQuery, "text", $$v)
          },
          expression: "listQuery.text",
        },
      }),
      _c("br"),
      _c(
        "el-button",
        {
          directives: [{ name: "waves", rawName: "v-waves" }],
          staticClass: "filter-item",
          attrs: { type: "primary", icon: "el-icon-search" },
          on: { click: _vm.handleFilter },
        },
        [_vm._v(" " + _vm._s(_vm.$t("table.search")) + " ")]
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            "element-loading-text": "Loading...",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "IosKey" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_vm._v(" " + _vm._s(row.key))]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "AndroidKey" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_vm._v(" " + _vm._s(row.andKey) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Value" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_vm._v(" " + _vm._s(row.value) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Language" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_vm._v(" " + _vm._s(row.languageKey) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.actions"),
              align: "center",
              width: "230",
              "class-name": "fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(row)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("table.edit")) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticStyle: { width: "400px", "margin-left": "50px" },
              attrs: {
                rules: _vm.rules,
                model: _vm.tempLocData,
                "label-position": "left",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("columnType.key"),
                    title: "iOS Key",
                    "label-width": "200px",
                    prop: "key",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "ios key", disabled: true },
                    model: {
                      value: _vm.tempLocData.key,
                      callback: function ($$v) {
                        _vm.$set(_vm.tempLocData, "key", $$v)
                      },
                      expression: "tempLocData.key",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("columnType.andKey"),
                    "label-width": "200px",
                    prop: "andKey",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Android key", disabled: true },
                    model: {
                      value: _vm.tempLocData.andKey,
                      callback: function ($$v) {
                        _vm.$set(_vm.tempLocData, "andKey", $$v)
                      },
                      expression: "tempLocData.andKey",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("columnType.value"),
                    prop: "value",
                    "label-width": "200px",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "text" },
                    model: {
                      value: _vm.tempLocData.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.tempLocData, "value", $$v)
                      },
                      expression: "tempLocData.value",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("columnType.languageKey"),
                    prop: "languageKey",
                    "label-width": "200px",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Language", disabled: true },
                    model: {
                      value: _vm.tempLocData.languageKey,
                      callback: function ($$v) {
                        _vm.$set(_vm.tempLocData, "languageKey", $$v)
                      },
                      expression: "tempLocData.languageKey",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("table.cancel")) + " ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus === "create"
                        ? _vm.createData()
                        : _vm.updateData()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("table.save")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }