var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _c(
        "el-form",
        {
          ref: "loginForm",
          staticClass: "login-form",
          attrs: {
            model: _vm.loginForm,
            rules: _vm.loginRules,
            autocomplete: "on",
            "label-position": "left",
          },
        },
        [
          _c(
            "div",
            { staticClass: "title-container" },
            [
              _c("h3", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("login.title"))),
              ]),
              _c("lang-select", { staticClass: "set-language" }),
            ],
            1
          ),
          _c("div", { staticClass: "title-container" }, [
            _vm.isVerificationCodeVisible
              ? _c("div", { staticClass: "countdown" }, [
                  _c("h5", { staticClass: "title" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.countdownSeconds <= 0
                            ? _vm.$t("login.timeUpResendCode")
                            : _vm.countdownSeconds +
                                _vm.$t("login.timeUpSecondsLeft")
                        ) +
                        " "
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
          _vm.loginWithEmail
            ? _c(
                "el-form-item",
                { attrs: { prop: "email" } },
                [
                  _c(
                    "span",
                    { staticClass: "svg-container" },
                    [_c("svg-icon", { attrs: { name: "user" } })],
                    1
                  ),
                  _c("el-input", {
                    ref: "email",
                    attrs: {
                      placeholder: "E-mail",
                      name: "email",
                      type: "text",
                      tabindex: "0",
                      autocomplete: "on",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.onInputChange("email")
                      },
                    },
                    model: {
                      value: _vm.loginForm.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.loginForm, "email", $$v)
                      },
                      expression: "loginForm.email",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.loginWithPhone
            ? _c(
                "el-form-item",
                [
                  _c("span", { staticClass: "svg-container" }, [
                    _c("b", [_vm._v("+")]),
                  ]),
                  _c("el-input", {
                    attrs: { placeholder: _vm.$t("login.countryCode") },
                    on: {
                      input: function ($event) {
                        return _vm.onInputChange("phone")
                      },
                    },
                    model: {
                      value: _vm.loginForm.countryCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.loginForm, "countryCode", $$v)
                      },
                      expression: "loginForm.countryCode",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.loginWithPhone
            ? _c(
                "el-form-item",
                [
                  _c(
                    "span",
                    { staticClass: "svg-container" },
                    [_c("svg-icon", { attrs: { name: "user" } })],
                    1
                  ),
                  _c("el-input", {
                    staticStyle: { flex: "1" },
                    attrs: { placeholder: _vm.$t("login.phoneNumber") },
                    on: {
                      input: function ($event) {
                        return _vm.onInputChange("phone")
                      },
                    },
                    model: {
                      value: _vm.loginForm.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.loginForm, "phone", $$v)
                      },
                      expression: "loginForm.phone",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.isVerificationCodeVisible
            ? _c(
                "el-button",
                {
                  attrs: { disabled: !_vm.resendLinkActive, type: "secondary" },
                  on: { click: _vm.resendCode },
                },
                [_vm._v(" " + _vm._s(_vm.$t("login.resendCode")) + " ")]
              )
            : _vm._e(),
          _vm.isVerificationCodeVisible
            ? _c(
                "el-form-item",
                { attrs: { prop: "code" } },
                [
                  _c(
                    "span",
                    { staticClass: "svg-container" },
                    [_c("svg-icon", { attrs: { name: "code" } })],
                    1
                  ),
                  _c("el-input", {
                    ref: "code",
                    attrs: {
                      placeholder: _vm.$t("login.enterCode"),
                      name: "code",
                      type: "text",
                      tabindex: "0",
                      autocomplete: "on",
                    },
                    model: {
                      value: _vm.loginForm.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.loginForm, "code", $$v)
                      },
                      expression: "loginForm.code",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          !_vm.isVerificationCodeVisible
            ? _c(
                "el-button",
                {
                  staticStyle: { width: "100%", "margin-bottom": "30px" },
                  attrs: { loading: _vm.loading, type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.handleLogin.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("login.sendCode")) + " ")]
              )
            : _vm._e(),
          _vm.isVerificationCodeVisible
            ? _c(
                "el-button",
                {
                  staticStyle: { width: "100%", "margin-bottom": "30px" },
                  attrs: { loading: _vm.loading, type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.handleValidation.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("login.logIn")) + " ")]
              )
            : _vm._e(),
          _c(
            "el-link",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.toggleLoginMethod.apply(null, arguments)
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.loginWithEmail
                      ? _vm.$t("login.loginWithPhone")
                      : _vm.$t("login.loginWithEmail")
                  ) +
                  " "
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.$t("login.thirdparty"), visible: _vm.showDialog },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.$t("login.thirdpartyTips")) + " "),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("social-sign"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }