var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", {}, [
    _vm.list.length
      ? _c("div", [
          _c(
            "div",
            { staticClass: "app-container" },
            [
              _c(
                "div",
                { staticClass: "button-container" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn-add-freezone",
                      attrs: { type: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.handleCreate()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-plus" }),
                      _vm._v(
                        " " + _vm._s(_vm.$t("freeZone.addNewSponsor")) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading",
                    },
                  ],
                  attrs: {
                    data: _vm.list,
                    "element-loading-text": "Loading...",
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c(
                    "el-table-column",
                    {
                      attrs: { align: "center", label: "ID", width: "95" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ $index }) {
                              return [_vm._v(_vm._s($index))]
                            },
                          },
                        ],
                        null,
                        false,
                        1833292881
                      ),
                    },
                    [_vm._v(" an ")]
                  ),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("freeZone.company"),
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _vm._v(" " + _vm._s(row.sponsorshipName) + " "),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4013328022
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: _vm.$t("freeZone.name"), align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [_vm._v(" " + _vm._s(row.name) + " ")]
                          },
                        },
                      ],
                      null,
                      false,
                      228539768
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("freeZone.latitude"),
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [_vm._v(" " + _vm._s(row.latitude) + " ")]
                          },
                        },
                      ],
                      null,
                      false,
                      204011183
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("freeZone.longitude"),
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [_vm._v(" " + _vm._s(row.longitude) + " ")]
                          },
                        },
                      ],
                      null,
                      false,
                      323422780
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: _vm.$t("freeZone.range"), align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [_vm._v(" " + _vm._s(row.range) + " ")]
                          },
                        },
                      ],
                      null,
                      false,
                      132084960
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("freeZone.state"),
                      align: "center",
                      width: "180",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.isActive
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "success" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleFreeZoneActive(
                                            row,
                                            false
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("freeZone.active")) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "danger" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleFreeZoneActive(
                                            row,
                                            true
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("freeZone.passive")) +
                                          " "
                                      ),
                                    ]
                                  ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4117561596
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("freeZone.actions"),
                      align: "center",
                      "class-name": "fixed-width",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleUpdate(row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("freeZone.edit")) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "danger" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showDeleteConfirmation(row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("freeZone.delete")) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      179829806
                    ),
                  }),
                ],
                1
              ),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: _vm.textMap[_vm.dialogStatus],
                    visible: _vm.dialogFormVisible,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogFormVisible = $event
                    },
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "dataForm",
                      staticStyle: { width: "400px", "margin-left": "50px" },
                      attrs: {
                        rules: _vm.rules,
                        model: _vm.tempFreeZoneData,
                        "label-position": "left",
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("freeZone.name"),
                            title: "First Name",
                            "label-width": "200px",
                            prop: "name",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "FreeZone Name" },
                            model: {
                              value: _vm.tempFreeZoneData.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.tempFreeZoneData, "name", $$v)
                              },
                              expression: "tempFreeZoneData.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("freeZone.company"),
                            title: "Company Name",
                            "label-width": "200px",
                            prop: "sponsorshipId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "Select Company" },
                              model: {
                                value: _vm.tempFreeZoneData.sponsorshipId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.tempFreeZoneData,
                                    "sponsorshipId",
                                    $$v
                                  )
                                },
                                expression: "tempFreeZoneData.sponsorshipId",
                              },
                            },
                            _vm._l(_vm.options, function (option) {
                              return _c("el-option", {
                                key: option.value,
                                attrs: {
                                  label: option.label,
                                  value: option.value,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("freeZone.latitude"),
                            "label-width": "200px",
                            prop: "latitude",
                          },
                        },
                        [
                          _c("numeric-input", {
                            attrs: { placeholder: "FreeZone Latitude" },
                            on: { error: _vm.handleError },
                            model: {
                              value: _vm.tempFreeZoneData.latitude,
                              callback: function ($$v) {
                                _vm.$set(_vm.tempFreeZoneData, "latitude", $$v)
                              },
                              expression: "tempFreeZoneData.latitude",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("freeZone.longitude"),
                            "label-width": "200px",
                            prop: "longitude",
                          },
                        },
                        [
                          _c("numeric-input", {
                            attrs: { placeholder: "FreeZone Longitude" },
                            on: { error: _vm.handleError },
                            model: {
                              value: _vm.tempFreeZoneData.longitude,
                              callback: function ($$v) {
                                _vm.$set(_vm.tempFreeZoneData, "longitude", $$v)
                              },
                              expression: "tempFreeZoneData.longitude",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("freeZone.range"),
                            "label-width": "200px",
                            prop: "range",
                          },
                        },
                        [
                          _c("numeric-input", {
                            attrs: { placeholder: "FreeZone Range" },
                            on: { error: _vm.handleError },
                            model: {
                              value: _vm.tempFreeZoneData.range,
                              callback: function ($$v) {
                                _vm.$set(_vm.tempFreeZoneData, "range", $$v)
                              },
                              expression: "tempFreeZoneData.range",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", {
                        staticStyle: { height: "300px" },
                        attrs: { id: "map" },
                      }),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "isCity",
                            label: _vm.$t("freeZone.isCity"),
                            "label-width": "200px",
                          },
                        },
                        [
                          _c("el-switch", {
                            attrs: {
                              "inline-prompt": "",
                              "active-text": _vm.$t("freeZone.yes"),
                              "inactive-text": _vm.$t("freeZone.no"),
                            },
                            model: {
                              value: _vm.tempFreeZoneData.isCity,
                              callback: function ($$v) {
                                _vm.$set(_vm.tempFreeZoneData, "isCity", $$v)
                              },
                              expression: "tempFreeZoneData.isCity",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "isActive",
                            label: _vm.$t("freeZone.state"),
                            "label-width": "200px",
                          },
                        },
                        [
                          _c("el-switch", {
                            attrs: {
                              "inline-prompt": "",
                              "active-text": _vm.$t("freeZone.active"),
                              "inactive-text": _vm.$t("freeZone.passive"),
                            },
                            model: {
                              value: _vm.tempFreeZoneData.isActive,
                              callback: function ($$v) {
                                _vm.$set(_vm.tempFreeZoneData, "isActive", $$v)
                              },
                              expression: "tempFreeZoneData.isActive",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.dialogFormVisible = false
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("table.cancel")) + " ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.dialogStatus === "create"
                                ? _vm.createData()
                                : _vm.updateData()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("table.confirm")) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: "Delete Item",
                    visible: _vm.deleteConfirmationVisible,
                    width: "30%",
                    "before-close": _vm.handleDeleteConfirmationClose,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.deleteConfirmationVisible = $event
                    },
                  },
                },
                [
                  _c("span", [_vm._v(_vm._s(_vm.$t("freeZone.deletePopup")))]),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.deleteConfirmationVisible = false
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("company.cancel")))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.deleteItem },
                        },
                        [_vm._v(_vm._s(_vm.$t("company.ok")))]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ])
      : _c("div", [_vm._v(_vm._s(_vm.$t("company.dataNotAvailable")))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }