
import { AdminApi } from "@/wwapi/apis/admin-api";
import { UserModule } from "@/store/modules/user";
import { LocalizationTextsForAdminResponse } from "@/wwapi/models/localization-texts-for-admin-response";
import { LocalizationForAdminResponseModel } from "@/wwapi/models/localization-for-admin-response-model";
import { Component, Prop, Vue } from "vue-property-decorator";
import UploadExcelComponent from "@/components/UploadExcel/index.vue";
import { LocalizationApi } from "@/wwapi";
import axios from "axios";
@Component({
  name: "ExportZip",
  components: {
    UploadExcelComponent,
  },
})
export default class extends Vue {
  @Prop({ required: true }) private beforeUpload!: Function;
  private list: any[] = [];
  private listLoading = true;
  private downloadLoading = false;
  private file = "";
  private listQuery = {
    text: null,
  };

  private localizationTextsVersion = 0;

  created() {
    this.fetchData();
    this.file = "";
  }

  private async fetchData() {
    this.listLoading = true;
    const adminApi = new AdminApi();

    const redisResponse = await adminApi.getRedisValue({
      key: "localization_texts_version",
    });
    this.localizationTextsVersion = redisResponse.data.data.value;
    const response = await adminApi.localizationTextsForAdminPost({}, 0);
    const responseModel: LocalizationTextsForAdminResponse = response.data;
    const localizationModel: LocalizationForAdminResponseModel =
      responseModel.data;

    this.list = localizationModel;
    console.log("firstkey: " + localizationModel[0].key);
    // Just to simulate the time of the request
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  private async filterData() {
    this.listLoading = true;
    const adminApi = new AdminApi();

    const response = await adminApi.localizationFilterPost(this.listQuery, 0);
    const responseModel: LocalizationTextsForAdminResponse = response.data;
    const texts: LocalizationForAdminResponseModel = responseModel.data;
    if (texts.length && texts.length > 0) this.list = texts;
    else this.list = [];

    // this.list = response.data.items;
    // Just to simulate the time of the request
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  private handleFilter() {
    this.filterData();
  }

  private handleUpdate(row: any) {
    this.$router.push({
      path: `localizations/detail?key=${row.key}&andKey=${row.andKey}`,
    });
  }

  private async handleLocalizationTextsVersion() {
    const adminApi = new AdminApi();
    const response = await adminApi.setRedisValue({
      key: "localization_texts_version",
      value: this.localizationTextsVersion,
    });
    if (response && response.data) {
      this.$notify({
        title: "Success",
        message: "Successfully sent localizationTextsVersion updated",
        type: "success",
        duration: 2000,
      });
    }
  }

  private async handleDownload() {
    const email = UserModule.email;
    this.downloadLoading = true;
    const adminApi = new AdminApi();

    const response = await adminApi.localizationExportClientArtifactsPost({
      email: email,
    });
    if (response && response.data) {
      this.downloadLoading = false;
      this.$notify({
        title: "Success",
        message: `Successfully sent localization keys to ${email}`,
        type: "success",
        duration: 2000,
      });
    }
  }

  private async handleClick(e: any) {
    // const files = (e.target as HTMLInputElement).files;
    // if (files) {
    //   const rawFile = files[0]; // only use files[0]
    //   this.upload(rawFile);
    // }
    if (e && e.target && e.target.files) this.file = e.target.files[0];
  }

  private async submitFile() {
    const formData = new FormData();
    formData.append("file", this.file);
    // const response =
    //   await localizationApi.localizationImportLocalizationFilePostForm(
    //     formData
    //   );
    const headers = { "Content-Type": "multipart/form-data" };
    const response = await axios.post(
      process.env.VUE_APP_API_URL?.concat(
        "/localization/importLocalizationFilev2"
      ) ??
        "https://wewalk-backend-v2-stage.azurewebsites.net/api/v2/localization/importLocalizationFilev2",
      formData,
      { headers }
    );
    if (response && response.data) {
      this.downloadLoading = false;
      this.$notify({
        title: "Success",
        message: "Successfully imported localization excels new version!",
        type: "success",
        duration: 2000,
      });
    } else {
      this.downloadLoading = false;
      this.$notify({
        title: "Error",
        message:
          "Couldn't complete the request, new version may not have been uploaded correctly.",
        type: "error",
        duration: 2000,
      });
    }
  }
}
