
import { Component, Vue, Watch } from "vue-property-decorator";
import { AdminApi } from "@/wwapi/apis/admin-api";
import { cloneDeep } from "lodash";
import { Form } from "element-ui";
import { defaultBrandSubjectData } from "../../defaultModels/IBrandSubjectData";
import { GetBrandSubjectsResponse } from "@/wwapi/models/get-brand-subjects-response";

@Component({
  name: "BrandSubjectList",
})
export default class extends Vue {
  private list: GetBrandSubjectsResponse[] = [];
  private brandOptions: Array<any> = [];
  private subjectOptions: Array<any> = [];
  private listLoading = true;
  private dialogFormVisible = false;
  private deleteConfirmationVisible = false;
  private deleteItemId = null;
  private dialogStatus = "";
  private tempBrandSubjectData = defaultBrandSubjectData;

  private textMap = {
    update: "Edit",
    create: "Create",
  };

  created() {
    this.fetchData();
  }

  mounted() {
    this.getBrands();
    this.getSubjects();
  }

  private async getBrands() {
    const adminApi = new AdminApi();
    const response = await adminApi.getBrands();
    this.brandOptions = response.data.data.map((option) => {
      return {
        label: option.name,
        value: option.id,
      };
    });
  }

  private async getSubjects() {
    const adminApi = new AdminApi();
    const response = await adminApi.getSubjects();
    this.subjectOptions = response.data.data.map((option) => {
      return {
        label: option.name,
        value: option.id,
      };
    });
  }

  private async fetchData() {
    this.listLoading = true;
    const adminApi = new AdminApi();
    const response = await adminApi.getBrandSubjects({ isActive: false });
    if (response.data.data.length) this.list = response.data.data;
    else this.list = [];
    console.log(
      "data returned from getBrandSubjects" + JSON.stringify(response.data.data)
    );
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  showDeleteConfirmation(row: any) {
    this.deleteItemId = row.id;
    this.deleteConfirmationVisible = true;
  }

  handleDeleteConfirmationClose(done: any) {
    if (done) {
      this.deleteItemId = null;
      this.deleteConfirmationVisible = false;
    }
  }

  async deleteItem() {
    console.log(`Deleting item with ID: ${this.deleteItemId}`);
    const adminApi = new AdminApi();
    const { data } = await adminApi.deleteBrandSubject({
      brandSubjectId: this.deleteItemId,
    });
    if (data.status.code === "success") {
      const index = this.list.findIndex((v: any) => v.id === this.deleteItemId);
      this.list.splice(index, 1);
      this.$notify({
        title: "Success",
        message: "Successfully brandSubject deleted",
        type: "success",
        duration: 2000,
      });
    }
    this.deleteItemId = null;
    this.deleteConfirmationVisible = false;
  }

  private handleUpdate(row: any) {
    this.tempBrandSubjectData = Object.assign({}, row);
    this.dialogStatus = "update";
    this.dialogFormVisible = true;
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate();
    });
  }

  private updateData() {
    const adminApi = new AdminApi();
    (this.$refs.dataForm as Form).validate(async (valid) => {
      if (valid) {
        const tempData = Object.assign({}, this.tempBrandSubjectData);
        const { data } = await adminApi.updateBrandSubject({
          id: tempData.id,
          consultSubjectId: tempData.consultSubjectId,
          consultBrandId: tempData.consultBrandId,
          orderNo: tempData.orderNo,
          isConsultFree: tempData.isConsultFree,
          isActive: tempData.isActive,
          consultSubjectName: tempData.consultSubjectName,
          consultBrandName: tempData.consultBrandName,
          isRemoved: tempData.isRemoved,
        });
        const index = this.list.findIndex((v: any) => v.id === tempData.id);
        if (data.status.code === "success") {
          console.log(tempData);
          this.list.splice(index, 1, tempData);
          this.dialogFormVisible = false;
          this.$notify({
            title: "Success",
            message: "Successfully update the brandSubject",
            type: "success",
            duration: 2000,
          });
        }
      }
    });
  }

  private handleCreate() {
    this.tempBrandSubjectData = cloneDeep(defaultBrandSubjectData);
    this.dialogStatus = "create";
    this.dialogFormVisible = true;
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate();
    });
  }

  private createData() {
    const adminApi = new AdminApi();
    (this.$refs.dataForm as Form).validate(async (valid) => {
      if (valid) {
        const tempData = Object.assign({}, this.tempBrandSubjectData);
        const { data } = await adminApi.createBrandSubject({
          consultSubjectId: tempData.consultSubjectId,
          consultBrandId: tempData.consultBrandId,
          orderNo: tempData.orderNo,
          isConsultFree: tempData.isConsultFree,
          isActive: tempData.isActive,
          consultSubjectName: tempData.consultSubjectName,
          consultBrandName: tempData.consultBrandName,
          isRemoved: tempData.isRemoved,
        });
        if (data && data.data) {
          this.list = [...this.list, data.data];
        }
        this.dialogFormVisible = false;
        this.$notify({
          title: "Success",
          message: "Successfully added new brandSubject",
          type: "success",
          duration: 2000,
        });
      }
    });
  }

  private async toggleBrandSubjectActive(row: any, isActive: boolean) {
    const adminApi = new AdminApi();
    row.isActive = isActive;
    const { data } = await adminApi.updateBrandSubject({
      id: row.id,
      consultSubjectName: row.consultSubjectName,
      consultBrandName: row.consultBrandName,
      consultBrandId: row.consultBrandId,
      consultSubjectId: row.consultSubjectId,
      orderNo: row.orderNo,
      isConsultFree: row.isConsultFree,
      isActive: row.isActive,
      isRemoved: row.isRemoved,
    });
    if (data.status.code === "success") {
      if (!isActive) {
        const index = this.list.findIndex((v: any) => v.id === data.data.id);
        this.list.splice(index, 1, row);
      }
      this.$notify({
        title: "Success",
        message: "Successfully brandSubject status changed",
        type: "success",
        duration: 2000,
      });
    }
  }
}
