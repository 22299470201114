
import { Component, Vue } from "vue-property-decorator";
import { AppModule } from "@/store/modules/app";
import settings from "../../settings";
@Component({
  name: "Login",
})
export default class extends Vue {
  get language() {
    return AppModule.language;
  }

  private handleSetLanguage(lang: string) {
    console.log("Language selected : " + lang);
    this.$i18n.locale = lang;
    AppModule.SetLanguage(lang);
    document.documentElement.lang = lang;

    document.cookie = `language=${lang}; SameSite=None; Secure`;
    const title = this.$route.meta?.title
      ? `${this.$t(`route.${this.$route.meta.title}`)} - ${settings.title}`
      : `${settings.title}`;
    document.title = title;
    
    this.$message({
      message: this.$t("components.changeLanguageTips").toString(),
      type: "success",
    });
  }
}
