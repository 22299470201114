import { render, staticRenderFns } from "./activations.vue?vue&type=template&id=33147eec&scoped=true"
import script from "./activations.vue?vue&type=script&lang=js"
export * from "./activations.vue?vue&type=script&lang=js"
import style0 from "./activations.vue?vue&type=style&index=0&id=33147eec&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33147eec",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('33147eec')) {
      api.createRecord('33147eec', component.options)
    } else {
      api.reload('33147eec', component.options)
    }
    module.hot.accept("./activations.vue?vue&type=template&id=33147eec&scoped=true", function () {
      api.rerender('33147eec', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/users/activations.vue"
export default component.exports