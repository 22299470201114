
import { Component, Prop, Vue } from 'vue-property-decorator'
import settings from '@/settings'

@Component({
  name: 'SidebarLogo'
})
export default class extends Vue {
  @Prop({ required: true }) private collapse!: boolean;

  private title = settings.title;
}
