
import { AdminApi } from "@/wwapi/apis/admin-api";
import { UserModule } from "@/store/modules/user";
import { LocalizationTextsForAdminResponse } from "@/wwapi/models/localization-texts-for-admin-response";
import { LocalizationForAdminResponseModel } from "@/wwapi/models/localization-for-admin-response-model";
import { Component, Prop, Vue } from "vue-property-decorator";
import UploadExcelComponent from "@/components/UploadExcel/index.vue";
import { LocalizationApi } from "@/wwapi";
import axios from "axios";
import { StatusModel, StatusModelCodeEnum } from "@/wwapi/models/status-model";

interface Row {
  key: string;
  andKey: string;
  value: string;
  languageKey: string;
  isNew: boolean;
}

@Component({
  name: "ExportZip",
  components: {
    UploadExcelComponent,
  },
})


export default class extends Vue {
  private row: Row = {
    key: '',
    andKey: '',
    value: '',
    languageKey: '',
    isNew: false,
  };
  @Prop({ required: true }) private beforeUpload!: Function;
  private list: any[] = [];
  private listLoading = true;
  private downloadLoading = false;
  private file = "";
  private isModalVisible = false;
  private responseMessage = "";
  private responseErrors: string[] = [];
  private listQuery = {
    text: null,
  };

  private localizationTextsVersion = 0;

  created() {
    this.filterData();
    this.file = "";
  }

  private async fetchData() {
    this.listLoading = true;
    const adminApi = new AdminApi();

    const redisResponse = await adminApi.getRedisValue({
      key: "localization_texts_version",
    });
    this.localizationTextsVersion = redisResponse.data.data.value;
    const response = await adminApi.localizationTextsForAdminPost({}, 0);
    const responseModel: LocalizationTextsForAdminResponse = response.data;
    const localizationModel: LocalizationForAdminResponseModel =
      responseModel.data;

    this.list = localizationModel;
    console.log("firstkey: " + localizationModel[0].key);
    // Just to simulate the time of the request
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  private addNewRow() {
    const newRow = {
      key: '',
      andKey: '',
      value: '',
      languageKey: '',
      isNew: true, // Yeni satır için işaret
    };
    this.list.unshift(newRow); // Yeni satırı listenin başına ekler
  }

  private async saveRow(index: number) {
    const row = this.list[index];

    try {
      if (row.isNew) {
        // Yeni satır için create işlemi
        const response = await axios.post(
          process.env.VUE_APP_API_URL?.concat('/localization/createLocalizationKeyV2') ??
          'https://wewalk-backend-v2-stage.azurewebsites.net/api/v2/localization/createLocalizationKeyV2',
          {
            key: row.key,
            andKey: row.andKey,
            value: row.value,
            languageKey: row.languageKey,
            plistKey: row.plistKey || '',
            speakerKey: row.speakerKey || '',
            voiceKey: row.voiceKey || ''
          }
        );

        if (response && response.data.status.code === StatusModelCodeEnum.Success) {
          this.list[index] = { ...response.data.data, isNew: false }; // Satır güncellenir
          this.$notify({
            title: this.$t('localization.success') as string,
            message: this.$t('localization.rowUpdated') as string,
            type: 'success',
            duration: 2000,
          });
        } else {
          throw new Error(response?.data.status.message || 'create_failed');
        }
      } else {
        // Mevcut satır için update işlemi
        const response = await axios.post(
          process.env.VUE_APP_API_URL?.concat('/localization/updateLocalizationKeyV2') ??
          'https://wewalk-backend-v2-stage.azurewebsites.net/api/v2/localization/updateLocalizationKeyV2',
          {
            key: row.key,
            andKey: row.andKey,
            value: row.value,
            languageKey: row.languageKey,
          }
        );

        if (response && response.data.status.code === StatusModelCodeEnum.Success) {
          this.$notify({
            title: this.$t('localization.success') as string,
            message: this.$t('localization.rowUpdated') as string,
            type: 'success',
            duration: 2000,
          });
        } else {
          throw new Error(response?.data.status.message || 'update_failed');
        }
      }
    } catch (error) {
      console.error('Error saving row:', error);
      this.$notify({
        title: this.$t('localization.error') as string,
        message: this.$t('localization.updateFailed') as string,
        type: 'error',
        duration: 2000,
      });
    }
  }

  private deleteRow(index: number) {
    const row = this.list[index];

    // Tüm inputların boş olup olmadığını kontrol et
    const isRowEmpty = !row.key && !row.andKey && !row.languageKey && !row.value;

    if (isRowEmpty) {
      // Satır boşsa doğrudan listeden kaldır
      this.list.splice(index, 1);
      this.$notify({
        title: this.$t('localization.success') as string,
        message: this.$t('localization.rowDeleted') as string,
        type: 'success',
        duration: 2000,
      });
    } else {
      // Satır doluysa kullanıcıdan onay iste
      this.$confirm(
        this.$t('localization.confirmDeleteMessage') as string,
        this.$t('localization.warning') as string,
        {
          confirmButtonText: this.$t('localization.yes') as string,
          cancelButtonText: this.$t('localization.no') as string,
          type: 'warning',
        }
      ).then(async () => {
        try {
          // POST işlemi burada yapılır
          const response = await axios.post(
            process.env.VUE_APP_API_URL?.concat('/localization/deleteLocalizationKey') ??
            'https://wewalk-backend-v2-stage.azurewebsites.net/api/v2/localization/deleteLocalizationKey',
            row
          );
          if (response && response.data) {
            this.list.splice(index, 1); // Listeden sil
            this.$notify({
              title: this.$t('localization.success') as string,
              message: this.$t('localization.rowDeleted') as string,
              type: 'success',
              duration: 2000,
            });
          }
        } catch (error) {
          console.error('Error deleting row:', error);
          this.$notify({
            title: this.$t('localization.error') as string,
            message: this.$t('localization.deleteFailed') as string,
            type: 'error',
            duration: 2000,
          });
        }
      });
    }
  }

  private async filterData() {
    this.listLoading = true;
    const adminApi = new AdminApi();

    const redisResponse = await adminApi.getRedisValue({
      key: "localization_texts_version",
    });
    this.localizationTextsVersion = redisResponse.data.data.value;

    const response = await adminApi.localizationFilterPost(this.listQuery, 0);
    const responseModel: LocalizationTextsForAdminResponse = response.data;
    const texts: LocalizationForAdminResponseModel = responseModel.data;
    if (texts.length && texts.length > 0) this.list = texts;
    else this.list = [];

    // this.list = response.data.items;
    // Just to simulate the time of the request
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  private handleFilter() {
    this.filterData();
  }

  private handleUpdate(row: any) {
    this.$router.push({
      path: `localizations/detail?key=${row.key}&andKey=${row.andKey}`,
    });
  }

  private async handleLocalizationTextsVersion() {
    const adminApi = new AdminApi();
    const response = await adminApi.setRedisValue({
      key: "localization_texts_version",
      value: this.localizationTextsVersion,
    });
    if (response && response.data) {
      this.$notify({
        title: this.$t('localization.success') as string, // Hata başlığı çevirisi
        message: this.$t('localization.versionUpdated') as string,
        type: "success",
        duration: 2000,
      });
    }
  }

  private async handleDownload() {
    const email = UserModule.email;
    this.downloadLoading = true;
    const adminApi = new AdminApi();

    const response = await adminApi.localizationExportClientArtifactsPost({
      email: email,
    });
    if (response && response.data) {
      this.downloadLoading = false;
      this.$notify({
        title: this.$t('localization.success') as string,
        message: this.$t('localization.emailSent') as string + `${email}`,
        type: "success",
        duration: 2000,
      });
    }
  }

  private async handleClick(e: any) {
    // const files = (e.target as HTMLInputElement).files;
    // if (files) {
    //   const rawFile = files[0]; // only use files[0]
    //   this.upload(rawFile);
    // }
    if (e && e.target && e.target.files) this.file = e.target.files[0];
  }

  private isImporting = false;

  private async submitFile() {
    if (!this.file) {
      this.$notify({
        title: this.$t("localization.error") as string,
        message: this.$t("localization.fileRequired") as string,
        type: "error",
        duration: 2000,
      });
      return;
    }
    const formData = new FormData();
    formData.append("file", this.file);

    const loadingInstance = this.$loading({
      lock: true,
      text: "Please wait...",
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)',
    });

    try {
      this.isImporting = true; // Global loading başlat
      const headers = { "Content-Type": "multipart/form-data" };
      const response = await axios.post(
        process.env.VUE_APP_API_URL?.concat("/localization/importLocalizationFilev2") ??
        "https://wewalk-backend-v2-stage.azurewebsites.net/api/v2/localization/importLocalizationFilev2",
        formData,
        { headers }
      );

      if (response && response.data) {
        const { success, message, errors } = response.data;

        if (success) {
          this.responseMessage = message || this.$t("localization.fileImported") as string;
          this.responseErrors = errors || [];
        } else {
          this.responseMessage = message || this.$t("localization.importFailed") as string;
          this.responseErrors = errors || [];
        }

        this.isModalVisible = true; // Modalı aç
      } else {
        this.responseMessage = this.$t("localization.importFailed") as string;
        this.responseErrors = [];
        this.isModalVisible = true; // Modalı aç
      }
    } catch (error: any) {
      console.error("Import error:", error);

      // Axios error response kontrolü
      if (error.response && error.response.data) {
        const errorData = error.response.data;
        this.responseMessage = errorData.message || this.$t("localization.importFailed") as string;
        this.responseErrors = errorData.errors || [];
      } else {
        this.responseMessage = this.$t("localization.importFailed") as string;
        this.responseErrors = [this.$t("localization.unknownError") as string];
      }

      this.isModalVisible = true; // Modalı aç
    } finally {
      this.isImporting = false;
      loadingInstance.close();
    }
  }
}
