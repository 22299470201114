var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.isImporting
        ? _c("el-loading", {
            attrs: {
              fullscreen: true,
              text: _vm.$t("localization.importInProgress"),
            },
          })
        : _vm._e(),
      _c(
        "el-card",
        { staticClass: "action-card" },
        [
          _c(
            "el-row",
            {
              staticClass: "top-row",
              attrs: {
                gutter: 20,
                type: "flex",
                justify: "start",
                align: "middle",
              },
            },
            [
              _c(
                "el-col",
                { staticClass: "action-col", attrs: { xs: 24, sm: 8, md: 4 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        loading: _vm.downloadLoading,
                        type: "primary",
                        icon: "el-icon-download",
                        disabled: _vm.isImporting,
                      },
                      on: { click: _vm.handleDownload },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("localization.export")) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "action-col", attrs: { xs: 24, sm: 8, md: 10 } },
                [
                  _c(
                    "div",
                    { staticClass: "file-input-wrapper" },
                    [
                      _c("input", {
                        ref: "file",
                        staticClass: "file-input",
                        attrs: {
                          name: "file",
                          type: "file",
                          accept: ".xlsx, .xls",
                          disabled: _vm.isImporting,
                        },
                        on: { change: _vm.handleClick },
                      }),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            loading: _vm.downloadLoading,
                            type: "primary",
                            icon: "el-icon-upload",
                            disabled: _vm.isImporting,
                          },
                          on: { click: _vm.submitFile },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("localization.import")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-col",
                { staticClass: "action-col", attrs: { xs: 24, sm: 8, md: 10 } },
                [
                  _c(
                    "div",
                    { staticClass: "version-update-wrapper" },
                    [
                      _c("el-input", {
                        staticClass: "version-input",
                        attrs: {
                          maxlength: "4",
                          placeholder: _vm.$t("localization.updateVersion"),
                          disabled: _vm.isImporting,
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.handleLocalizationTextsVersion.apply(
                              null,
                              arguments
                            )
                          },
                        },
                        model: {
                          value: _vm.localizationTextsVersion,
                          callback: function ($$v) {
                            _vm.localizationTextsVersion = $$v
                          },
                          expression: "localizationTextsVersion",
                        },
                      }),
                      _c(
                        "el-button",
                        {
                          directives: [{ name: "waves", rawName: "v-waves" }],
                          attrs: {
                            type: "primary",
                            icon: "el-icon-refresh",
                            disabled: _vm.isImporting,
                          },
                          on: { click: _vm.handleLocalizationTextsVersion },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("localization.updateVersion")) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("br"),
      _c(
        "el-card",
        { staticClass: "action-card" },
        [
          _c(
            "el-row",
            {
              staticClass: "bottom-row",
              attrs: {
                gutter: 20,
                type: "flex",
                justify: "space-between",
                align: "middle",
              },
            },
            [
              _c(
                "el-col",
                { staticClass: "action-col", attrs: { xs: 24, sm: 8, md: 6 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "success", icon: "el-icon-plus" },
                      on: { click: _vm.addNewRow },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("localization.addNewLocalization")) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "action-col", attrs: { xs: 24, sm: 8, md: 6 } },
                [
                  _c(
                    "div",
                    { staticClass: "search-wrapper" },
                    [
                      _c("el-input", {
                        staticClass: "search-input",
                        attrs: { placeholder: _vm.$t("table.search") },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.handleFilter.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.listQuery.text,
                          callback: function ($$v) {
                            _vm.$set(_vm.listQuery, "text", $$v)
                          },
                          expression: "listQuery.text",
                        },
                      }),
                      _c(
                        "el-button",
                        {
                          directives: [{ name: "waves", rawName: "v-waves" }],
                          attrs: { type: "primary", icon: "el-icon-search" },
                          on: { click: _vm.handleFilter },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("table.search")) + " ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c("br"),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              attrs: {
                data: _vm.list,
                "element-loading-text": "Loading...",
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: _vm.$t("localization.iosKey") },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("el-input", {
                          class: { "readonly-input": !row.isNew },
                          attrs: {
                            readonly: !row.isNew,
                            placeholder: _vm.$t("localization.enterIosKey"),
                          },
                          model: {
                            value: row.key,
                            callback: function ($$v) {
                              _vm.$set(row, "key", $$v)
                            },
                            expression: "row.key",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: _vm.$t("localization.androidKey") },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("el-input", {
                          class: { "readonly-input": !row.isNew },
                          attrs: {
                            readonly: !row.isNew,
                            placeholder: _vm.$t("localization.enterAndroidKey"),
                          },
                          model: {
                            value: row.andKey,
                            callback: function ($$v) {
                              _vm.$set(row, "andKey", $$v)
                            },
                            expression: "row.andKey",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: _vm.$t("localization.languageKey") },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("el-input", {
                          class: { "readonly-input": !row.isNew },
                          attrs: {
                            readonly: !row.isNew,
                            placeholder: _vm.$t("localization.enterLanguage"),
                          },
                          model: {
                            value: row.languageKey,
                            callback: function ($$v) {
                              _vm.$set(row, "languageKey", $$v)
                            },
                            expression: "row.languageKey",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: _vm.$t("localization.value") },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("el-input", {
                          attrs: {
                            placeholder: _vm.$t("localization.enterValue"),
                          },
                          model: {
                            value: row.value,
                            callback: function ($$v) {
                              _vm.$set(row, "value", $$v)
                            },
                            expression: "row.value",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: _vm.$t("localization.actions") },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                _vm.saveRow(_vm.list.indexOf(row))
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("localization.save")) + " "
                            ),
                          ]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "danger" },
                            on: {
                              click: function ($event) {
                                _vm.deleteRow(_vm.list.indexOf(row))
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("localization.delete")) + " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Import Result",
            visible: _vm.isModalVisible,
            width: "50%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isModalVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("p", [_vm._v(_vm._s(_vm.responseMessage))]),
            _vm.responseErrors.length
              ? _c(
                  "ul",
                  { staticClass: "error-list" },
                  _vm._l(_vm.responseErrors, function (error, index) {
                    return _c("li", { key: index }, [
                      _vm._v(" " + _vm._s(error) + " "),
                    ])
                  }),
                  0
                )
              : _c("p", [_vm._v(_vm._s(_vm.$t("localization.noErrorsFound")))]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.isModalVisible = false
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.isImporting
        ? _c("el-loading", {
            attrs: {
              fullscreen: true,
              text: _vm.$t("localization.importInProgress"),
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }