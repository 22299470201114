
import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import Uploader from "../../utils/uploader";
import { AdminApi } from "@/wwapi/apis/admin-api";
import { OtaFileResponse } from "@/wwapi/models/get-all-ota-files-response";
import moment from "moment";

interface OtaForm {
  wirelessFile: File | null;
  applicationFile: File | null;
  description: string;
  wirelessVersionName: string;
  applicationVersionName: string;
  installOrder: string;
  [key: string]: any; // Dinamik anahtarlar için indeks imzası
}

@Component
export default class extends Vue {
  private uploadModalVisible = false;
  private loading = false;
  private list: OtaFileResponse[] = [];
  private totalItems = 0;
  private currentPage = 1;
  private pageSize = 10;
  private otaForm: OtaForm = {
    wirelessFile: null,
    applicationFile: null,
    description: "",
    wirelessVersionName: "",
    applicationVersionName: "",
    installOrder: "",
  };

  private listLoading = true;

  private rules = {
    wirelessFile: [
      {
        required: true,
        message: "Please select a Wireless file",
        trigger: "change",
      },
    ],
    applicationFile: [
      {
        required: true,
        message: "Please select an Application file",
        trigger: "change",
      },
    ],
    wirelessVersionName: [
      {
        required: true,
        message: "Please enter a Wireless version name",
        trigger: "blur",
      },
      {
        pattern: /^[0-9]+\.[0-9]+\.[0-9]+$/,
        message: "Version must be in the format 1.1.1000",
        trigger: "blur",
      },
    ],
    applicationVersionName: [
      {
        required: true,
        message: "Please enter an Application version name",
        trigger: "blur",
      },
      {
        pattern: /^[0-9]+\.[0-9]+\.[0-9]+$/,
        message: "Version must be in the format 1.1.1000",
        trigger: "blur",
      },
    ],
    installOrder: [
      {
        required: true,
        message: "Please select an Install Priority",
        trigger: "change",
      },
    ],
  };

  created() {
    this.fetchOtaVersions();
  }

  private showUploadModal() {
    this.uploadModalVisible = true;
  }

  private handleWirelessFileChange(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      this.otaForm.wirelessFile = target.files[0];
    }
  }

  private handleApplicationFileChange(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      this.otaForm.applicationFile = target.files[0];
    }
  }

  private validateVersionInput(field: string) {
    const value = this.otaForm[field];
    if (/[^0-9.]/.test(value)) {
      this.otaForm[field] = value.replace(/[^0-9.]/g, "");
    }
  }

  private formatDate(date: string) {
    return moment(date).format("DD.MM.YYYY HH:mm");
  }

  private handlePageChange(page: number) {
    this.currentPage = page;
    this.fetchOtaVersions();
  }

  private async submitOtaVersion() {
    (this.$refs.otaForm as any).validate(async (valid: boolean) => {
      if (!valid) {
        this.$message.error("Please fill all required fields.");
        return;
      }

      if (!this.otaForm.wirelessFile || !this.otaForm.applicationFile) {
        this.$message.error(
          "Please select both Wireless and Application files."
        );
        return;
      }

      if (
        !this.otaForm.wirelessVersionName ||
        !this.otaForm.applicationVersionName
      ) {
        this.$message.error(
          "Please enter both Wireless and Application version numbers."
        );
        return;
      }

      this.loading = true;

      try {
        const wirelessFile = this.otaForm.wirelessFile;
        const wirelessFileName = `${Date.now()}-${wirelessFile.name}`;
        const wirelessFileType = wirelessFile.type;
        const wirelessFileSize = wirelessFile.size;

        const applicationFile = this.otaForm.applicationFile;
        const applicationFileName = `${Date.now()}-${applicationFile.name}`;
        const applicationFileType = applicationFile.type;
        const applicationFileSize = applicationFile.size;

        console.log("Uploading Wireless File:", wirelessFile.name, wirelessFile.size);
    console.log("Uploading Application File:", applicationFile.name, applicationFile.size);


        // Dosyaları Base64 formatına çevirme
        // const wirelessFileBase64 = await this.convertFileToBase64(wirelessFile);
        // const applicationFileBase64 = await this.convertFileToBase64(
        //   applicationFile
        // );

        // AWS S3'ye yükleme işlemi
        const uploader = new Uploader();
        // const wirelessResponse = await uploader.uploadImage({
        //   file: wirelessFileBase64,
        //   fileName: wirelessFileName,
        //   contentType: wirelessFileType,
        // });

        // const applicationResponse = await uploader.uploadImage({
        //   file: applicationFileBase64,
        //   fileName: applicationFileName,
        //   contentType: applicationFileType,
        // });

      


        //BASE64 yerine doğrudan gönderim yapılabilir mi?
        const wirelessResponse = await uploader.uploadFile({
      file: wirelessFile, // Base64 değil, doğrudan binary
      fileName: wirelessFileName,
      contentType: wirelessFileType,
    });

    const applicationResponse = await uploader.uploadFile({
      file: applicationFile, // Base64 değil, doğrudan binary
      fileName: applicationFileName,
      contentType: applicationFileType,
    });

  
    const wirelessUrl = wirelessResponse.Location;
    const applicationUrl = applicationResponse.Location;

    console.log("Wireless AWS URL:", wirelessUrl);
    console.log("Application AWS URL:", applicationUrl);
        // OTA versiyon oluşturma
        const otaData = {
          wirelessFileName: wirelessFileName,
          wirelessFileSize: wirelessFileSize,
          wirelessUploadDate: new Date().toISOString(),
          wirelessAwsUrl: wirelessUrl,
          wirelessVersion: 1, // veya başka bir versiyon mantığı
          wirelessVersionName: this.otaForm.wirelessVersionName,
          applicationFileName: applicationFileName,
          applicationFileSize: applicationFileSize,
          applicationUploadDate: new Date().toISOString(),
          applicationAwsUrl: applicationUrl,
          applicationVersion: 1, // veya başka bir versiyon mantığı
          applicationVersionName: this.otaForm.applicationVersionName,
          installOrder: this.otaForm.installOrder,
          description: this.otaForm.description,
        };

        const createResponse = await axios.post(
          process.env.VUE_APP_API_URL?.concat("/otafiles/createOtaVersion") ??
            "/otafiles/createOtaVersion",
          otaData
        );

        this.$message.success("OTA version created successfully.");
        this.uploadModalVisible = false;
        this.fetchOtaVersions(); // Listeyi güncelle
      } catch (error: any) {
        console.error("Error: ", error.response?.data || error.message); // Hata durumunda konsola hata mesajını yazdır
        this.$message.error("Failed to create OTA version.");
      } finally {
        this.loading = false;
      }
    });
  }
  
  private convertFileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const result = reader.result as string;
      const base64File = result.split(',')[1]; // Virgülden sonraki kısmı al
      resolve(base64File);
    };
    reader.onerror = (error) => reject(error);
  });
}

  private async fetchOtaVersions() {
    this.listLoading = true;
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/otafiles/all`
      );
      const responseModel: any[] = response.data; // Veri modeline göre güncellenebilir
      this.list = responseModel;
      this.totalItems = responseModel.length;
      console.log("Response Data: ", response.data); // Gelen veriyi konsola yazdır
    } catch (error) {
      this.$message.error("Failed to fetch OTA versions.");
    } finally {
      this.listLoading = false;
    }
  }

  private get paginatedList() {
    const start = (this.currentPage - 1) * this.pageSize;
    const end = this.currentPage * this.pageSize;
    return this.list.slice(start, end);
  }
}
