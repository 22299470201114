var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.showUploadModal } },
        [_vm._v("Upload OTA")]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.uploadModalVisible,
            title: "Upload OTA v2",
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.uploadModalVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "otaForm",
              attrs: {
                model: _vm.otaForm,
                rules: _vm.rules,
                "label-width": "200px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Install Priority", prop: "installOrder" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "Select Priority" },
                      model: {
                        value: _vm.otaForm.installOrder,
                        callback: function ($$v) {
                          _vm.$set(_vm.otaForm, "installOrder", $$v)
                        },
                        expression: "otaForm.installOrder",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "Wireless", value: "Wireless" },
                      }),
                      _c("el-option", {
                        attrs: { label: "Application", value: "Application" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Wireless File", prop: "wirelessFile" } },
                [
                  _c("input", {
                    attrs: { type: "file" },
                    on: { change: _vm.handleWirelessFileChange },
                  }),
                ]
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "Application File", prop: "applicationFile" },
                },
                [
                  _c("input", {
                    attrs: { type: "file" },
                    on: { change: _vm.handleApplicationFileChange },
                  }),
                ]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Wireless Version",
                    prop: "wirelessVersionName",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { placeholder: "Enter Wireless Version Name" },
                    on: {
                      input: function ($event) {
                        return _vm.validateVersionInput("wirelessVersionName")
                      },
                    },
                    model: {
                      value: _vm.otaForm.wirelessVersionName,
                      callback: function ($$v) {
                        _vm.$set(_vm.otaForm, "wirelessVersionName", $$v)
                      },
                      expression: "otaForm.wirelessVersionName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Application Version",
                    prop: "applicationVersionName",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "50%" },
                    attrs: { placeholder: "Enter Application Version Name" },
                    on: {
                      input: function ($event) {
                        return _vm.validateVersionInput(
                          "applicationVersionName"
                        )
                      },
                    },
                    model: {
                      value: _vm.otaForm.applicationVersionName,
                      callback: function ($$v) {
                        _vm.$set(_vm.otaForm, "applicationVersionName", $$v)
                      },
                      expression: "otaForm.applicationVersionName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Description" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.otaForm.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.otaForm, "description", $$v)
                      },
                      expression: "otaForm.description",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.uploadModalVisible = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.submitOtaVersion },
                },
                [_vm._v(" Submit ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.paginatedList,
            "element-loading-text": "Loading...",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Upload Date" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.formatDate(row.wirelessUploadDate)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Wireless File" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "a",
                      {
                        staticClass: "link-button",
                        attrs: { href: row.wirelessAwsUrl, target: "_blank" },
                      },
                      [_vm._v("Download File")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Application File" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "a",
                      {
                        staticClass: "link-button",
                        attrs: {
                          href: row.applicationAwsUrl,
                          target: "_blank",
                        },
                      },
                      [_vm._v("Download File")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Install Order" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_vm._v(" " + _vm._s(row.installOrder) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Install Order" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_vm._v(" " + _vm._s(row.installOrder) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Wireless Version" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_vm._v(" " + _vm._s(row.wirelessVersionName) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Application Version" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _vm._v(" " + _vm._s(row.applicationVersionName) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Description" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [_vm._v(" " + _vm._s(row.description) + " ")]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.currentPage,
          "page-size": _vm.pageSize,
          layout: "total, prev, pager, next",
          total: _vm.totalItems,
        },
        on: { "current-change": _vm.handlePageChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }