
import { Component, Vue, Watch } from "vue-property-decorator";
import { Route } from "vue-router";
import { Dictionary } from "vue-router/types/router";
import { Form as ElForm, Input } from "element-ui";
import { UserModule } from "@/store/modules/user";
import { isValidEmail } from "@/utils/validate";
import LangSelect from "@/components/LangSelect/index.vue";
import { UserApi } from "@/wwapi";

@Component({
  name: "Login",
  components: {
    LangSelect,
  },
})
export default class extends Vue {
  private validateEmail = (rule: any, value: string, callback: Function) => {
    if (!isValidEmail(value)) {
      callback(new Error("Please enter the correct email"));
    } else {
      callback();
    }
  };

  private loginForm = {
    email: "",
    countryCode: "",
    phone: "",
    code: "",
  };

  private loginRules = {
    email: [{ validator: this.validateEmail, trigger: "blur" }],
  };

  private loading = false;
  private showDialog = false;
  private capsTooltip = false;
  private redirect?: string;
  private otherQuery: Dictionary<string> = {};
  private isVerificationCodeVisible = false;
  private countdownSeconds: number = 300; // 5 dakika
  private countdownInterval: any = null;
  // private resendLinkActive: boolean = false;
  private loginWithEmail: boolean = true; // Default olarak email ile giriş
  private loginWithPhone: boolean = false;
  private disablePhoneInputBool: boolean = false;
  private activeDisabled: boolean = false;
  private email: string = "";
  private countryCode: string = "";
  private phone: string = "";

  @Watch("$route", { immediate: true })
  private onRouteChange(route: Route) {
    // TODO: remove the "as Dictionary<string>" hack after v4 release for vue-router
    // See https://github.com/vuejs/vue-router/pull/2050 for details
    const query = route.query as Dictionary<string>;
    if (query) {
      this.redirect = query.redirect;
      this.otherQuery = this.getOtherQuery(query);
    }
  }

  private toggleLoginMethod() {
    this.loginWithEmail = !this.loginWithEmail;
    this.loginWithPhone = !this.loginWithPhone;
    this.clearInputs(); // Inputları temizleme
    this.stopCountdown(); // Geri sayımı durdur ve sıfırla
  }

  private clearInputs() {
    this.loginForm.email = "";
    this.loginForm.countryCode = "";
    this.loginForm.phone = "";
    this.loginForm.code = "";
    this.resendLinkActive = false; // Yeniden gönder butonunu sıfırla
    this.isVerificationCodeVisible = false;
  }
  mounted() {
    if (this.loginForm.email === "") {
      (this.$refs.email as Input).focus();
    }
  }

  private handleLogin() {
    if (this.loginForm.countryCode && this.loginForm.phone) {
      console.log("phone login");
      // Telefon numarasıyla giriş yapılacak servis çağrısı
      this.handlePhoneLogin();
      this.stopCountdown();
    } else {
      console.log("mail login");
      if (this.loginForm.email) {
        // E-posta ile giriş yapılacak servis çağrısı
        this.handleEmailLogin();
        this.stopCountdown();
      }
    }
  }

  private resetLoginForm() {
    this.isVerificationCodeVisible = false; // Doğrulama kodu alanını gizle
    this.loginForm.email = ""; // E-posta alanını temizle
    this.loginForm.code = ""; // Kod alanını temizle
    this.loginForm.phone = ""; // Kod alanını temizle
    this.loginForm.countryCode = ""; // Kod alanını temizle
    this.stopCountdown();
  }

  private stopCountdown() {
    clearInterval(this.countdownInterval); // Geri sayımı durdur
    this.countdownSeconds = 300; // Geri sayımı sıfırla
    // this.resendLinkActive = false; // Yeniden kod gönderme bağlantısını devre dışı bırak
  }

  private onInputChange(inputType: string) {
    console.log("change event " + inputType);
    // Kullanıcı e-posta veya telefon numarası değişikliği yaptığında
    if (inputType === "email" || inputType === "phone") {
      // Doğrulama kodu görünüyorsa, formu ve geri sayımı resetle
      if (this.isVerificationCodeVisible) {
        this.resetLoginFormButKeepEmailOrPhone(inputType);
      }
    }
  }

  private resetLoginFormButKeepEmailOrPhone(keptInputType: string) {
    const email = this.loginForm.email;
    const phone = this.loginForm.phone;
    const countryCode = this.loginForm.countryCode;
    // Tüm formu resetle
    this.resetLoginForm();
    // Kullanıcının değiştirmediği input değerini geri yükle
    if (keptInputType === "email") {
      this.loginForm.email = email;
    } else if (keptInputType === "phone") {
      this.loginForm.phone = phone;
      this.loginForm.countryCode = countryCode;
    }
    // Geri sayımı başlatma
    this.startCountdown();
  }

  private async handlePhoneLogin() {
    
    // Boşlukları kaldır ve numaranın uzunluğunu kontrol et
    let cleanedPhone = this.loginForm.phone.replace(/\s+/g, "");
    if (cleanedPhone.length !== 10) {
      this.$notify({
        title: String(this.$t("login.loginErrorTitle")),
        message: String(this.$t("login.invalidPhoneLength")),
        type: "error",
        duration: 2000,
      });
      return; // Numara uygun formatta değilse işlemi durdur
    }

    if (this.loginForm.countryCode !== "90") {
      this.$notify({
        title: String(this.$t("login.loginWarningTitle")),
        message: String(this.$t("login.invalidCountry")),
        type: "warning",
        duration: 5000,
      });
      // SMS gönderim fonksiyonunun çağrılmasını önle
      return;
    }

    // Telefon numarasıyla giriş yapılacak servis çağrısı
    const authApi = new UserApi();

    try {
      const valid = await (this.$refs.loginForm as ElForm).validate();
      if (valid) {
        this.loading = true;
        try {
          const resp = await authApi.authSigninSendSmsCode(this.loginForm);
          console.log("API yanıtı alındı");
          console.log(resp);
          if (resp.status === 200) {
            this.isVerificationCodeVisible = true;
            this.startCountdown();
            this.loading = false;
          } else {
            this.loading = false;
            this.$notify({
              title: String(this.$t("login.loginErrorTitle")),
              message: String(this.$t("login.loginError")),
              type: "error",
              duration: 2000,
            });
          }
        } catch (error: any) {
          this.loading = false;
          const errorMessage =
            error.status.message === "pop_login_phone_invalid_user_type"
              ? String(this.$t("login.notAdmin"))
              : String(this.$t("login.loginError"));
          this.$notify({
            title: String(this.$t("login.loginErrorTitle")),
            message: errorMessage,
            type: "error",
            duration: 2000,
          });
        }
      } else {
        console.log("Form geçersiz");
      }
    } catch (err) {
      this.loading = false;
      this.$notify({
        title: String(this.$t("login.loginErrorTitle")),
        message: String(this.$t("login.loginError")),
        type: "error",
        duration: 2000,
      });
    }
  }

  private handleEmailLogin() {
    console.log("handle email login");

    // E-posta adresi geçerliliğini kontrol et
    if (!isValidEmail(this.loginForm.email)) {
      this.$notify({
        title: String(this.$t("login.loginErrorTitle")),
        message: String(this.$t("login.invalidEmail")),
        type: "error",
        duration: 2000,
      });
      return; // E-posta geçersizse işlemi durdur
    }

    // E-posta ile giriş yapılacak servis çağrısı
    const authApi = new UserApi();

    try {
      (this.$refs.loginForm as ElForm).validate(async (valid: boolean) => {
        if (valid) {
          this.loading = true;
          authApi.authSigninSendEmailCode(this.loginForm).then(
            (resp) => {
              console.log(resp);
              if (
                resp &&
                resp.data &&
                resp.data.status &&
                resp.data.status.code === "success"
              ) {
                this.isVerificationCodeVisible = true;
                this.startCountdown();
                this.loading = false;
              } else {
                this.loading = false;
                const errorMessage =
                  resp.data.status.message ===
                  "pop_login_phone_invalid_user_type"
                    ? String(this.$t("login.notAdmin"))
                    : String(this.$t("login.loginError"));
                this.$notify({
                  title: String(this.$t("login.loginErrorTitle")),
                  message: errorMessage,
                  type: "error",
                  duration: 2000,
                });
              }
            },
            (reason) => {
              this.loading = false;
              this.$notify({
                title: String(this.$t("login.loginErrorTitle")),
                message: String(this.$t("login.loginError")),
                type: "error",
                duration: 2000,
              });
            }
          );
        } else {
          return false;
        }
      });
    } catch (err) {
      this.loading = false;
      this.$notify({
        title: String(this.$t("login.loginErrorTitle")),
        message: String(this.$t("login.loginError")),
        type: "error",
        duration: 2000,
      });
    }
  }

  private handleSmsCodeValidation() {
    console.log("handle sms validation");
    const authApi = new UserApi();

    try {
      (this.$refs.loginForm as ElForm).validate(async (valid: boolean) => {
        if (valid) {
          this.loading = true;
          authApi.authSigninValidateSmsCode(this.loginForm).then(
            (resp) => {
              if (
                resp &&
                resp.data &&
                resp.data.status &&
                resp.data.status.code === "success"
              ) {
                // Başarılı giriş işlemi
                this.loading = false;
                if (
                  resp.data.data.userType &&
                  resp.data.data.userType === "admin"
                ) {
                  console.log("Sms Login response data: ", resp.data.data);
                  UserModule.Login(resp.data.data);
                  this.$router
                    .push({
                      path: this.redirect || "/",
                      query: this.otherQuery,
                    })
                    .catch((err) => {
                      console.warn(err);
                    });
                  // Just to simulate the time of the request
                  setTimeout(() => {
                    this.loading = false;
                  }, 0.5 * 1000);
                } else {
                  this.loading = false;
                  this.$notify({
                    title: String(this.$t("login.loginErrorTitle")),
                    message: String(this.$t("login.notAdmin")),
                    type: "error",
                    duration: 2000,
                  });
                }
              } else {
                // Başarısız giriş işlemi
                this.loading = false;
                this.$notify({
                  title: String(this.$t("login.loginErrorTitle")),
                  message: String(this.$t("login.loginError")),
                  type: "error",
                  duration: 2000,
                });
              }
            },
            (reason) => {
              this.loading = false;
              this.$notify({
                title: String(this.$t("login.loginErrorTitle")),
                message: String(this.$t("login.loginError")),
                type: "error",
                duration: 2000,
              });
            }
          );
        } else {
          return false;
        }
      });
    } catch (err) {
      this.loading = false;
      this.$notify({
        title: String(this.$t("login.loginErrorTitle")),
        message: String(this.$t("login.loginError")),
        type: "error",
        duration: 2000,
      });
    }
  }

  private handleEmailCodeValidation() {
    console.log("handle email validation");
    const authApi = new UserApi();

    try {
      (this.$refs.loginForm as ElForm).validate(async (valid: boolean) => {
        if (valid) {
          this.loading = true;
          authApi.authSigninValidateEmailCode(this.loginForm).then(
            (resp) => {
              console.log(resp);
              if (
                resp &&
                resp.data &&
                resp.data.status &&
                resp.data.status.code === "success"
              ) {
                console.log("success");
                // Başarılı giriş işlemi
                this.loading = false;
                if (
                  resp.data.data.userType &&
                  resp.data.data.userType === "admin"
                ) {
                  console.log("user is admin");
                  UserModule.Login(resp.data.data);
                  this.$router
                    .push({
                      path: this.redirect || "/",
                      query: this.otherQuery,
                    })
                    .catch((err) => {
                      console.warn(err);
                    });
                  // Just to simulate the time of the request
                  setTimeout(() => {
                    this.loading = false;
                  }, 0.5 * 1000);
                } else {
                  this.loading = false;
                  this.$notify({
                    title: String(this.$t("login.loginErrorTitle")),
                    message: String(this.$t("login.notAdmin")),
                    type: "error",
                    duration: 2000,
                  });
                }
              } else {
                // Başarısız giriş işlemi
                this.loading = false;
                this.$notify({
                  title: String(this.$t("login.loginErrorTitle")),
                  message: String(this.$t("login.loginError")),
                  type: "error",
                  duration: 2000,
                });
              }
            },
            (reason) => {
              this.loading = false;
              this.$notify({
                title: String(this.$t("login.loginErrorTitle")),
                message: String(this.$t("login.loginError")),
                type: "error",
                duration: 2000,
              });
            }
          );
        } else {
          return false;
        }
      });
    } catch (err) {
      this.loading = false;
      this.$notify({
        title: String(this.$t("login.loginErrorTitle")),
        message: String(this.$t("login.loginError")),
        type: "error",
        duration: 2000,
      });
    }
  }

  private handleValidation() {
    if (this.loginForm.countryCode && this.loginForm.phone) {
      // Telefon numarasıyla giriş yapılacak servis çağrısı
      this.handleSmsCodeValidation();
    } else {
      // E-posta ile giriş yapılacak servis çağrısı
      this.handleEmailCodeValidation();
    }
  }

  private resendLinkActive: boolean = false;

  private startCountdown() {
    this.resendLinkActive = false; // Butonu devre dışı bırak
    this.countdownSeconds = 300; // 300 saniyeye ayarla
    this.countdownInterval = setInterval(() => {
      this.countdownSeconds -= 1;
      if (this.countdownSeconds <= 0) {
        clearInterval(this.countdownInterval);
        this.resendLinkActive = true; // Geri sayım bittiğinde butonu etkinleştir
      }
    }, 1000);
  }

  // Yeniden kod gönderme fonksiyonu
  private resendCode() {
    this.resendLinkActive = false;
    this.countdownSeconds = 300; // Geri sayımı yeniden 300 saniyeye ayarla
    this.startCountdown(); // Geri sayımı yeniden başlat
    // Kodun yeniden gönderilmesi için gerekli işlem burada yapılacak

    this.handleLogin();
  }

  private getOtherQuery(query: Dictionary<string>) {
    return Object.keys(query).reduce((acc, cur) => {
      if (cur !== "redirect") {
        acc[cur] = query[cur];
      }
      return acc;
    }, {} as Dictionary<string>);
  }
}
