var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-dropdown",
    {
      attrs: { "hide-on-click": false, "show-timeout": 100, trigger: "click" },
    },
    [
      _c("el-button", { attrs: { plain: "" } }, [
        _vm._v(" Platfroms(" + _vm._s(_vm.platforms.length) + ") "),
        _c("i", { staticClass: "el-icon-caret-bottom el-icon--right" }),
      ]),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        [
          _c(
            "el-checkbox-group",
            {
              staticStyle: { padding: "5px 15px" },
              model: {
                value: _vm.platforms,
                callback: function ($$v) {
                  _vm.platforms = $$v
                },
                expression: "platforms",
              },
            },
            _vm._l(_vm.platformsOptions, function (item) {
              return _c(
                "el-checkbox",
                { key: item.key, attrs: { label: item.key } },
                [_vm._v(" " + _vm._s(item.name) + " ")]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }