var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { class: { "has-logo": _vm.showLogo } },
    [
      _vm.showLogo
        ? _c("sidebar-logo", { attrs: { collapse: _vm.isCollapse } })
        : _vm._e(),
      _c(
        "el-scrollbar",
        { attrs: { "wrap-class": "scrollbar-wrapper" } },
        [
          _c(
            "el-menu",
            {
              attrs: {
                "default-active": _vm.activeMenu,
                collapse: _vm.isCollapse,
                "background-color": _vm.variables.menuBg,
                "text-color": _vm.variables.menuText,
                "active-text-color": _vm.menuActiveTextColor,
                "unique-opened": false,
                "collapse-transition": false,
                mode: "vertical",
              },
            },
            _vm._l(_vm.routes, function (route) {
              return _c("sidebar-item", {
                key: route.path,
                attrs: {
                  item: route,
                  "base-path": route.path,
                  "is-collapse": _vm.isCollapse,
                },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }