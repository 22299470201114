import { UserModule } from '@/store/modules/user';
import { Configuration } from './configuration';

import globalAxios, { AxiosInstance } from 'axios';

const _BASE_PATH = process.env.VUE_APP_API_URL;
console.log('env is ' + _BASE_PATH);
export let BASE_PATH = '';
if (_BASE_PATH) {
  BASE_PATH = _BASE_PATH.replace(/\/+$/, '');
}

const _BASE_PATH_V3 = process.env.VUE_APP_API_URL_V3;
console.log('env is ' + _BASE_PATH_V3);
export let BASE_PATH_V3 = '';
if (_BASE_PATH_V3) {
  BASE_PATH_V3 = _BASE_PATH_V3.replace(/\/+$/, '');
}

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|'
};

/**
 *
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = globalAxios
  ) {
    const token = UserModule.token;
    if (token) {
      this.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  name: 'RequiredError' = 'RequiredError';
  constructor(public field: string, msg?: string) {
    super(msg);
  }
}
