var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-dropdown",
    { attrs: { "show-timeout": 100, trigger: "click" } },
    [
      _c("el-button", { attrs: { plain: "" } }, [
        _vm._v(" Link "),
        _c("i", { staticClass: "el-icon-caret-bottom el-icon--right" }),
      ]),
      _c(
        "el-dropdown-menu",
        {
          staticClass: "no-padding",
          staticStyle: { width: "400px" },
          attrs: { slot: "dropdown" },
          slot: "dropdown",
        },
        [
          _c(
            "el-form-item",
            {
              staticStyle: { "margin-bottom": "0px" },
              attrs: { "label-width": "0px", prop: "sourceURL" },
            },
            [
              _c(
                "el-input",
                {
                  attrs: { placeholder: "Please enter the content" },
                  model: {
                    value: _vm.sourceURL,
                    callback: function ($$v) {
                      _vm.sourceURL = $$v
                    },
                    expression: "sourceURL",
                  },
                },
                [_c("template", { slot: "prepend" }, [_vm._v(" URL ")])],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }