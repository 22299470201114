import AWS from 'aws-sdk';

class Uploader {
    private s3: AWS.S3;

    constructor() {
        AWS.config.update({
            region: process.env.VUE_APP_AWS_REGION,
            accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
        });

        this.s3 = new AWS.S3();
    }

    uploadImage(payload: { file: string; fileName: string; contentType: string }): Promise<any> {
        const s3 = this.s3;
        return new Promise(function (resolve, reject) {
            const encodedImage = payload.file;
            const decodedImage = Buffer.from(encodedImage, 'base64');
            const params = {
                Body: decodedImage,
                Bucket: process.env.VUE_APP_S3_BUCKET_NAME!,
                Key: payload.fileName,
                ACL: 'public-read',
                ContentType: payload.contentType,
            };
            s3.upload(params, function (err: any, data: any) {
                if (err) {
                    reject(err);
                } else {
                    resolve(data);
                }
            });
        });
    }

    uploadFile(payload: { file: File; fileName: string; contentType: string }): Promise<any> {
        const s3 = this.s3;
        return new Promise(function (resolve, reject) {
          const file = payload.file;
          console.log(`Uploading File: ${file.name}`);
          console.log(`Content Type: ${payload.contentType}`);
          console.log(`File Size (bytes): ${file.size}`);
    
          const params = {
            Body: file,
            Bucket: process.env.VUE_APP_S3_BUCKET_NAME!,
            Key: payload.fileName,
            ACL: 'public-read',
            ContentType: payload.contentType,
          };
    
          s3.upload(params, function (err: any, data: any) {
            if (err) {
              console.error("AWS Upload Error:", err);
              reject(err);
            } else {
              console.log(`AWS Upload Success: ${data.Location}`);
              resolve(data);
            }
          });
        });
      }
}

export default Uploader;
